<script lang="ts" setup>
import { ProfilePhoto } from "@allaxis/ui";
import { useRouter } from "vue-router";

import InfiniteScroll from "@/components/InfiniteScroll.vue";
import MobileNav from "@/components/MobileNav.vue";
import NavBar from "@/components/NavBar.vue";
import TitleBar from "@/components/TitleBar.vue";

import useAuthStore from "@/stores/common/auth";
import useOverlayManager from "@/stores/common/overlay-manager";
import useUiStore from "@/stores/common/ui";

import useAsset from "@/hooks/useAsset";

import { ROUTES } from "@/router";

const props = withDefaults(defineProps<PropTypes>(), {
  hasSideBarMenuButton: false,
  hasBackButton: false,
  hasMobileBackButton: false,
  hasNavBar: true,
  hasTitleBar: true,
  hasMobileNav: true,
  showTopDivider: true,
  hasPageLoaded: false,
  // // eslint-disable-next-line @typescript-eslint/no-empty-function
  // scrollBottomHandler: () => {},
  // // eslint-disable-next-line @typescript-eslint/no-empty-function
  // scrollTopHandler: () => {},
});
const ui = useUiStore();
const router = useRouter();
const auth = useAuthStore();

const overlayManagerStore = useOverlayManager();

const asset = useAsset();

type PropTypes = {
  title?: string;
  hasSideBarMenuButton?: boolean;
  hasBackButton?: boolean;
  hasMobileBackButton?: boolean;
  hasNavBar?: boolean;
  hasTitleBar?: boolean;
  hasMobileNav?: boolean;
  showTopDivider?: boolean;
  hasPageLoaded?: boolean;
  scrollTopHandler?: () => void;
  scrollBottomHandler?: () => void;
};

function handleMobileLeftButtonTap() {
  if (props.hasMobileBackButton) {
    router.go(-1);
  } else {
    ui.toggleSidebar();
  }
}
</script>

<template>
  <template v-if="auth.user">
    <slot name="header" :toggleSidebar="ui.toggleSidebar">
      <NavBar
        @left-button-tap="ui.toggleSidebar"
        :show-divider="showTopDivider"
        @right-button-tap="() => $router.push({ name: ROUTES.SETTINGS })"
        right-icon="fa-sliders text-md"
      />
      <TitleBar
        @left-button-tap="handleMobileLeftButtonTap"
        :left-icon="props.hasMobileBackButton ? 'fa-arrow-left' : 'fa-bars'"
        :title="title || ''"
        :show-divider="showTopDivider"
      >
        <template v-if="!hasMobileBackButton" #leading-slot>
          <span class="flex justify-center w-16 cursor-pointer xs:w-20"> </span>
        </template>

        <template v-if="!title || title == ''" #default>
          <span class="flex flex-row items-center justify-center space-x-3">
            <img
              class="w-6 h-6 drop-shadow-md"
              src="../assets/logo.svg"
              alt=""
            />
            <h4 class="text-lg xs:text-2xl">Allaxis</h4>
          </span>
        </template>

        <template #trailing-slot>
          <a
            @click="overlayManagerStore.showUserBottomSheet()"
            class="flex flex-row items-center justify-end w-16 cursor-pointer xs:w-20"
          >
            <ProfilePhoto
              size="xs"
              :placeholder-text="auth.user.acronym"
              :image-url="
                asset(
                  'profile/small/' + auth.user.profile_photo?.filename,
                  !!auth.user.profile_photo
                )
              "
            />
          </a>
        </template>
      </TitleBar>
    </slot>

    <InfiniteScroll
      @hit-top="() => props.scrollTopHandler && props.scrollTopHandler()"
      @hit-bottom="
        () => props.scrollBottomHandler && props.scrollBottomHandler()
      "
      :has-page-loaded="props.hasPageLoaded"
      :has-page-top-observer="typeof props.scrollTopHandler === 'function'"
      :has-page-bottom-observer="
        typeof props.scrollBottomHandler === 'function'
      "
      root="#main"
    >
      <div
        id="page"
        class="flex flex-col items-center w-full min-h-screen-minus-nav"
      >
        <slot></slot>
      </div>
    </InfiniteScroll>

    <slot name="footer">
      <MobileNav />
    </slot>
  </template>
</template>
@/stores/common/auth @/stores/common/overlay-manager @/stores/common/ui
