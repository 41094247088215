<script lang="ts" setup>
import { ProfilePhoto, Input, Button } from "@allaxis/ui";
import { inject, ref } from "vue";

import LoadingMessageListItem from "@/components/LoadingMessageListItem.vue";
import MessageList from "@/components/MessageList.vue";

import useVaporUpload from "@/hooks/useVaporUpload";

import type { MessagePayloadType } from "@/types/appTypes";

const props = withDefaults(defineProps<PropTypes>(), {
  showUserIcon: false,
  isLoadingMessages: false,
  isLoadingUser: false,
  messages: () => [],
});
const emit = defineEmits<EmitTypes>();

const messageInputText = ref("");
const messageListScrollContainer = ref(null);

const appConfig = inject<{ content_url?: string }>("appConfig", {});
const isSending = ref(false);
const photos = ref<Array<File>>([]);
const photoUrls = ref<Array<string>>([]);

const { upload } = useVaporUpload();

function handleInput(event: Event) {
  const element = event.target as HTMLInputElement;
  const files = element.files as FileList;

  const localPhotoUrls: Array<string> = [];
  const localPhotos: Array<File> = [];

  Array.from(files).forEach((file) => {
    localPhotoUrls.push(URL.createObjectURL(file));
    localPhotos.push(file);
  });

  photoUrls.value.push(...localPhotoUrls);
  photos.value.push(...localPhotos);
}

async function handleMessageInput() {
  isSending.value = true;
  const localText = messageInputText.value;
  const s3PhotoObjects = await Promise.all(
    photos.value.map(async (photo) => {
      const response = await upload(photo);
      return {
        uuid: response.uuid,
        key: response.key,
        content_type: photo.name,
      };
    })
  );

  emit("message", { text: localText, photos: s3PhotoObjects }, () => {
    isSending.value = false;
    messageInputText.value = "";
    photoUrls.value = [];
    photos.value = [];
  });
}

defineExpose({
  messageListScrollContainer,
});

type PropTypes = {
  messages?: Array<Message>;
  title?: string;
  subtitle?: string;
  photoIconText: string;
  currentUser: User;
  showUserIcon?: boolean;
  isLoadingMessages?: boolean;
  isLoadingUser?: boolean;
};

type EmitTypes = {
  (e: "message", payload: MessagePayloadType, onComplete: () => void): void;
};
</script>

<template>
  <div id="chat-detail-container" class="flex flex-col flex-grow h-full">
    <div
      class="hidden bg-dynamic-neutral-light dark:bg-dynamic-neutral-900 bg-tint-opacity-100 flex-shrink-0 flex-row items-center px-4 border-b border-dynamic-neutral-200 dark:border-dynamic-neutral-800 md:flex aspect-landscape:hidden lg:!flex h-16"
    >
      <section class="flex flex-row items-center">
        <ProfilePhoto
          v-if="props.photoIconText"
          :image-url="
            props.currentUser.profile_photo
              ? appConfig.content_url +
                'profile/hd/' +
                props.currentUser.profile_photo?.filename
              : ''
          "
          :placeholder-text="props.photoIconText"
          class="w-12 h-12 mr-4"
          size="sm"
        />

        <div class="text-sm">
          <p>{{ props.title }}</p>
          <p class="text-dynamic-neutral-500">
            {{ props.subtitle }}
          </p>
        </div>
      </section>
    </div>
    <div
      class="flex flex-col items-center flex-grow p-4 space-y-4 overflow-y-auto"
      ref="messageListScrollContainer"
    >
      <div class="w-full max-w-screen-sm space-y-4">
        <slot name="top"></slot>

        <template v-if="props.messages.length > 0">
          <MessageList
            :messages="props.messages || []"
            :current-user="props.currentUser"
            :showIcon="props.showUserIcon"
          />
        </template>
        <template
          v-else-if="props.messages.length === 0 && props.isLoadingMessages"
        >
          <LoadingMessageListItem v-for="i in Array(10)" :key="i" />
        </template>

        <slot name="bottom"></slot>
      </div>
    </div>

    <div
      id="chat-message-input-container"
      class="sticky bottom-0 flex-col hidden p-2 space-y-2 border-t border-dynamic-neutral-200 dark:border-dynamic-neutral-800 md:flex bg-dynamic-neutral-light dark:bg-dynamic-neutral-900 bg-tint-opacity-100"
    >
      <div
        class="flex flex-row w-full space-x-2 scroll-p-2"
        v-if="photoUrls.length"
      >
        <div
          class="h-16 overflow-hidden border-2 rounded-md border-neutral-200 dark:border-neutral-700"
          v-for="(photoUrl, index) in photoUrls"
          :key="index"
        >
          <img class="h-full max-w-full" :src="photoUrl" />
        </div>
      </div>
      <div class="flex flex-row w-full space-x-2">
        <Button
          themed
          rounded
          type="primary"
          size="sm"
          icon="far fa-image"
          as="label"
          for="message-photo-input"
          class="cursor-pointer"
        />
        <input
          class="hidden"
          id="message-photo-input"
          type="file"
          multiple
          accept="image/gif, image/jpeg, image/png"
          @change="handleInput"
        />
        <Input
          id="message-input"
          placeholder="Message..."
          v-model="messageInputText"
          @keyup.enter.stop="handleMessageInput"
          class="flex-grow"
        />
        <Button themed type="primary" @click="handleMessageInput" text="Send" />
      </div>
    </div>
  </div>
</template>
