import { useLocalStorage } from "@vueuse/core";
import { acceptHMRUpdate, defineStore } from "pinia";
import { ref } from "vue";

import useNotificationStore from "@/stores/resources/notifications-store";

import usePlatform from "@/hooks/usePlatformFeatureCheck";

const usePreferences = defineStore("preferences", () => {
  const platform = usePlatform();

  const notificationStore = useNotificationStore();

  const isUsingSystemTheme = useLocalStorage<boolean>(
    "using-system-theme",
    true
  );
  const currentTheme = useLocalStorage<"light" | "dark" | null>(
    "theme",
    "light"
  );
  const userTheme = useLocalStorage<"light" | "dark">("user-theme", "light");

  const hasPermissionForNotifications = ref(
    "Notification" in window && Notification.permission
  );

  const hasThemeTint = useLocalStorage("has-theme-tint", false);
  const hasPaperDesign = useLocalStorage("has-paper-design", !platform.isIOS);
  const hasSpacedDesign = useLocalStorage("has-spaced-design", !platform.isIOS);
  const hasWiredDesign = useLocalStorage("has-wired-design", platform.isIOS);
  const hasGlassDesign = useLocalStorage("has-glass-design", platform.isIOS);
  const hasCompactListDesign = useLocalStorage(
    "has-compact-list-design",
    platform.isIOS
  );

  type ThemeColorType =
    | "pink"
    | "indigo"
    | "cyan"
    | "purple"
    | "yellow"
    | "lime";

  const themeColor = useLocalStorage<ThemeColorType>(
    "theme-color",
    platform.isIOS ? "pink" : "indigo"
  );

  const themeAccentColor = useLocalStorage<ThemeColorType>(
    "theme-accent-color",
    platform.isIOS ? "pink" : "indigo"
  );

  function toggleThemeTint() {
    if (hasThemeTint.value) {
      hasThemeTint.value = false;
      document.documentElement.classList.remove("theme-tint");
    } else {
      hasThemeTint.value = true;
      document.documentElement.classList.add("theme-tint");
    }
  }

  function togglePaperDesign() {
    if (hasPaperDesign.value) {
      hasPaperDesign.value = false;
      document.documentElement.classList.remove("paper-design");
    } else {
      hasPaperDesign.value = true;
      document.documentElement.classList.add("paper-design");
    }
  }

  function toggleCompactListDesign() {
    if (hasCompactListDesign.value) {
      hasCompactListDesign.value = false;
      document.documentElement.classList.remove("compact-list-design");
    } else {
      hasCompactListDesign.value = true;
      document.documentElement.classList.add("compact-list-design");
    }
  }

  function toggleSpacedDesign() {
    if (hasSpacedDesign.value) {
      hasSpacedDesign.value = false;
      document.documentElement.classList.remove("spaced-design");
    } else {
      hasSpacedDesign.value = true;
      document.documentElement.classList.add("spaced-design");
    }
  }

  function toggleWiredDesign() {
    if (hasWiredDesign.value) {
      hasWiredDesign.value = false;
      document.documentElement.classList.remove("wired-design");
    } else {
      hasWiredDesign.value = true;
      document.documentElement.classList.add("wired-design");
    }
  }

  function toggleGlassDesign() {
    if (hasGlassDesign.value) {
      hasGlassDesign.value = false;
      document.documentElement.classList.remove("glass-design");
    } else {
      hasGlassDesign.value = true;
      document.documentElement.classList.add("glass-design");
    }
  }

  function updateThemeColor(color: ThemeColorType) {
    resetThemeColors();
    switch (color) {
      case "pink":
        document.documentElement.classList.add("theme-allaxis-pink");
        break;
      case "purple":
        document.documentElement.classList.add("theme-allaxis-purple");
        break;
      case "indigo":
        document.documentElement.classList.add("theme-allaxis-indigo");
        break;
      case "cyan":
        document.documentElement.classList.add("theme-allaxis-cyan");
        break;
      case "lime":
        document.documentElement.classList.add("theme-allaxis-lime");
        break;
      case "yellow":
        document.documentElement.classList.add("theme-allaxis-yellow");
        break;
    }

    themeColor.value = color;

    updateThemeAccentColor(color);
    (window as any).updateStatusBarColor();
  }

  function resetThemeColors() {
    document.documentElement.classList.remove("theme-allaxis-pink");
    document.documentElement.classList.remove("theme-allaxis-purple");
    document.documentElement.classList.remove("theme-allaxis-indigo");
    document.documentElement.classList.remove("theme-allaxis-cyan");
    document.documentElement.classList.remove("theme-allaxis-lime");
    document.documentElement.classList.remove("theme-allaxis-yellow");
  }

  function updateThemeAccentColor(color: ThemeColorType) {
    resetThemeAccentColor();
    switch (color) {
      case "pink":
        document.documentElement.classList.add("theme-accent-allaxis-pink");
        break;
      case "purple":
        document.documentElement.classList.add("theme-accent-allaxis-purple");
        break;
      case "indigo":
        document.documentElement.classList.add("theme-accent-allaxis-indigo");
        break;
      case "cyan":
        document.documentElement.classList.add("theme-accent-allaxis-cyan");
        break;
      case "lime":
        document.documentElement.classList.add("theme-accent-allaxis-lime");
        break;
      case "yellow":
        document.documentElement.classList.add("theme-accent-allaxis-yellow");
        break;
    }

    themeAccentColor.value = color;
  }

  function resetThemeAccentColor() {
    document.documentElement.classList.remove("theme-accent-allaxis-pink");
    document.documentElement.classList.remove("theme-accent-allaxis-purple");
    document.documentElement.classList.remove("theme-accent-allaxis-indigo");
    document.documentElement.classList.remove("theme-accent-allaxis-cyan");
    document.documentElement.classList.remove("theme-accent-allaxis-lime");
    document.documentElement.classList.remove("theme-accent-allaxis-yellow");
  }

  function setupAppearance() {
    if (isUsingSystemTheme.value) turnOnSystemAppearanceListener();
    else turnOffSystemAppearanceListener();
  }

  function toggleAppearance(value?: "light" | "dark") {
    if (isUsingSystemTheme.value) return;
    userTheme.value ||= value
      ? value === "light"
        ? "dark"
        : "light"
      : undefined; // I will so regret this line...

    if (userTheme.value == "dark") {
      userTheme.value = "light";
      currentTheme.value = "light";
      document.documentElement.classList.remove("dark");
      return;
    }
    userTheme.value = "dark";
    currentTheme.value = "dark";
    document.documentElement.classList.add("dark");
  }

  function handleSystemAppearanceChange(e: MediaQueryListEvent) {
    if (!isUsingSystemTheme.value) return;
    if (e.matches) {
      currentTheme.value = "dark";
      document.documentElement.classList.add("dark");
      return;
    }

    currentTheme.value = "light";
    document.documentElement.classList.remove("dark");
    return;
  }

  function turnOnSystemAppearanceListener() {
    window
      .matchMedia("(prefers-color-scheme: dark)")
      .addEventListener("change", handleSystemAppearanceChange);
  }

  function turnOffSystemAppearanceListener() {
    window
      .matchMedia("(prefers-color-scheme: dark)")
      .removeEventListener("change", handleSystemAppearanceChange);
  }

  function toggleSystemAppearance(value?: boolean) {
    isUsingSystemTheme.value ||= !value;
    if (isUsingSystemTheme.value) {
      turnOffSystemAppearanceListener();

      if (userTheme.value === "dark") {
        document.documentElement.classList.add("dark");
        currentTheme.value = "dark";
      } else {
        document.documentElement.classList.remove("dark");
        currentTheme.value = "light";
      }

      isUsingSystemTheme.value = false;

      return;
    }

    currentTheme.value = null;
    isUsingSystemTheme.value = true;

    turnOnSystemAppearanceListener();

    console.log({
      currentTheme: currentTheme.value,
    });

    if (
      currentTheme.value === null &&
      window.matchMedia("(prefers-color-scheme: dark)").matches
    ) {
      currentTheme.value = "dark";
      document.documentElement.classList.add("dark");
    } else {
      currentTheme.value = "light";
      document.documentElement.classList.remove("dark");
    }
  }

  async function askForNotificationPermission() {
    if (!("Notification" in window)) return;
    const permission = await Notification.requestPermission();

    if (permission === "granted") {
      notificationStore.startPushNotifications();
      hasPermissionForNotifications.value = permission;
    }
  }

  return {
    userTheme,
    currentTheme,
    isUsingSystemTheme,
    toggleSystemAppearance,
    toggleAppearance,
    setupAppearance,

    hasPermissionForNotifications,

    askForNotificationPermission,
    hasPaperDesign,
    hasSpacedDesign,
    hasWiredDesign,
    hasGlassDesign,
    hasCompactListDesign,
    hasThemeTint,

    togglePaperDesign,
    toggleSpacedDesign,
    toggleWiredDesign,
    toggleGlassDesign,
    toggleCompactListDesign,
    toggleThemeTint,

    themeColor,
    themeAccentColor,

    updateThemeColor,
    updateThemeAccentColor,
  };
});

export default usePreferences;

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(usePreferences, import.meta.hot));
}
