import { defineComponent as C, openBlock as i, createBlock as j, resolveDynamicComponent as M, normalizeClass as T, withCtx as _, createElementBlock as u, createCommentVNode as y, toDisplayString as x, createElementVNode as d, Fragment as S, renderList as R, ref as P, mergeProps as ae, renderSlot as N, withModifiers as O, unref as E, getCurrentScope as Pe, onScopeDispose as Oe, watch as re, computed as B, inject as A, createVNode as q, normalizeStyle as Be, Teleport as le, cloneVNode as je, h as F, onMounted as U, watchEffect as Se, provide as Q, onUnmounted as se, createTextVNode as Ee } from "vue";
const Ae = {
  key: 0,
  class: "inline-flex items-center justify-center"
}, Ie = {
  key: 1,
  class: "w-5 h-5 mr-3 -ml-1 text-dynamic-neutral-light animate-spin",
  xmlns: "http://www.w3.org/2000/svg",
  fill: "none",
  viewBox: "0 0 24 24"
}, Le = /* @__PURE__ */ d("circle", {
  class: "opacity-25",
  cx: "12",
  cy: "12",
  r: "10",
  stroke: "currentColor",
  "stroke-width": "4"
}, null, -1), Re = /* @__PURE__ */ d("path", {
  class: "opacity-75",
  fill: "currentColor",
  d: "M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
}, null, -1), He = [
  Le,
  Re
], De = { key: 1 }, Pn = C({
  __name: "Button",
  props: {
    text: { default: "" },
    type: { default: "default" },
    size: { default: "md" },
    icon: { default: "" },
    rounded: { type: Boolean, default: !1 },
    disabled: { type: Boolean, default: !1 },
    loading: { type: Boolean, default: !1 },
    loadingText: { default: "Loading..." },
    themed: { type: Boolean, default: !1 },
    centered: { type: Boolean, default: !1 },
    isSubmit: { type: Boolean, default: !1 },
    as: { default: "button" }
  },
  emits: ["click"],
  setup(e, { emit: o }) {
    const t = e;
    function a(n) {
      if (t.disabled || t.loading)
        return null;
      o("click", n);
    }
    return (n, r) => (i(), j(M(n.as), {
      onClick: r[0] || (r[0] = (l) => a(l)),
      type: n.isSubmit ? "submit" : "button",
      class: T([{
        "!opacity-50": t.disabled || t.loading,
        "text-theme-aware-neutral-500 bg-theme-500 hover:bg-opacity-80 border-transparent focus:ring-theme": t.type === "primary" && t.themed,
        "dark:text-theme-500 text-theme-600 bg-theme-500 bg-opacity-10 hover:bg-opacity-20 dark:border-theme-500 border-theme-600 border-opacity-30 focus:ring-theme-500": t.type === "secondary" && t.themed,
        "text-dynamic-neutral-700 dark:text-dynamic-neutral-200 bg-dynamic-neutral-light dark:bg-dynamic-neutral-900 dark:hover:bg-dynamic-neutral-800 hover:bg-dynamic-neutral-50 border-dynamic-neutral-300 dark:border-dynamic-neutral-600 focus:ring-theme border": t.type === "default" && t.themed,
        "text-dynamic-neutral-light bg-indigo-600 hover:bg-indigo-700 border-transparent focus:ring-indigo-500 dark:bg-indigo-500 dark:hover:bg-indigo-400": t.type === "primary" && !t.themed,
        "text-indigo-500 bg-indigo-500 bg-opacity-10 hover:bg-opacity-20 border-transparent focus:ring-indigo-500": t.type === "secondary" && !t.themed,
        "text-dynamic-neutral-700 dark:text-dynamic-neutral-200 bg-dynamic-neutral-light dark:bg-dynamic-neutral-900 dark:hover:bg-dynamic-neutral-800 hover:bg-dynamic-neutral-50 border-dynamic-neutral-300 dark:border-dynamic-neutral-600 focus:ring-indigo-500 border": t.type === "default" && !t.themed,
        "px-2.5 py-1.5 text-xs": t.size === "xs" && !t.rounded,
        "px-3 py-2 text-sm": t.size === "sm" && !t.rounded,
        "px-4 py-2 text-sm": t.size === "md" && !t.rounded,
        "px-4 py-2 text-base": t.size === "lg" && !t.rounded,
        "px-6 py-3 text-base": t.size === "xl" && !t.rounded,
        "h-6 w-6 text-base items-center justify-center": t.rounded && t.size === "xs",
        "h-8 w-8 text-base items-center justify-center": t.rounded && t.size === "sm",
        "h-10 w-10 text-base items-center justify-center": t.rounded && t.size === "md",
        "h-12 w-12 text-base items-center justify-center": t.rounded && t.size === "lg",
        "h-16 w-16 text-base items-center justify-center": t.rounded && t.size === "xl",
        "rounded-full": t.rounded,
        rounded: !t.rounded && t.size === "xs",
        "rounded-md": !t.rounded && ["sm", "md", "lg", "xl"].includes(t.size),
        "justify-center": n.centered
      }, "inline-flex items-center overflow-auto font-medium border shadow-sm focus:ring-offset-white dark:focus:ring-offset-neutral-900 focus:outline-none focus:ring-2 focus:ring-offset-2"]),
      disabled: t.disabled
    }, {
      default: _(() => [
        t.icon ? (i(), u("span", Ae, [
          t.loading ? (i(), u("svg", Ie, He)) : (i(), u("i", {
            key: 0,
            class: T(t.icon)
          }, null, 2))
        ])) : y("", !0),
        t.text !== "" || t.loadingText == "" ? (i(), u("span", De, x(t.loading && t.loadingText ? t.loadingText : t.text), 1)) : y("", !0)
      ]),
      _: 1
    }, 8, ["type", "class", "disabled"]));
  }
}), Ne = { class: "relative z-0 inline-flex rounded-md shadow-sm" }, qe = ["onClick"], Me = {
  key: 0,
  class: "inline-flex items-center justify-center w-5 h-5 mr-1 text-dynamic-neutral-400 dark:text-dynamic-neutral-600"
}, On = C({
  __name: "ButtonGroup",
  props: {
    buttons: { default: () => [] }
  },
  emits: ["click"],
  setup(e, { emit: o }) {
    const t = e;
    function a(n, r) {
      if (t.buttons[r].disabled)
        return null;
      o("click", { event: n, buttonIndex: r });
    }
    return (n, r) => (i(), u("span", Ne, [
      (i(!0), u(S, null, R(t.buttons, (l, p) => (i(), u("button", {
        key: p,
        onClick: (m) => a(m, p),
        type: "button",
        class: T([{
          " bg-dynamic-neutral-light text-dynamic-neutral-700 dark:text-dynamic-neutral-200 border-dynamic-neutral-300 dark:border-dynamic-neutral-600 dark:bg-dynamic-neutral-900 hover:bg-dynamic-neutral-50 dark:hover:bg-dynamic-neutral-800": !l.isActive,
          "bg-theme-500 text-theme-aware-neutral-500 border-theme-500 hover:bg-theme-600": l.isActive
        }, "relative inline-flex items-center px-4 py-2 -ml-px text-sm font-medium border first:rounded-l-md last:rounded-r-md focus:z-10 focus:outline-none focus:ring-1 focus:ring-theme-500 focus:border-theme-500"])
      }, [
        l.icon ? (i(), u("span", Me, [
          d("i", {
            class: T([l.icon, "baseline"])
          }, null, 2)
        ])) : y("", !0),
        d("span", null, x(l.text), 1)
      ], 10, qe))), 128))
    ]));
  }
}), _e = C({
  __name: "Card",
  props: {
    rounded: { type: Boolean, default: !1 },
    padded: { type: Boolean, default: !0 },
    asElement: { default: "div" },
    position: { default: "relative" }
  },
  setup(e, { expose: o }) {
    const t = e, a = P(null);
    return o({
      rootRef: a
    }), (n, r) => (i(), j(M(n.asElement), ae(n.$attrs, {
      class: [{
        "!rounded-md": t.rounded,
        position: n.position
      }, "overflow-hidden bg-dynamic-neutral-light rounded-sm ui-item dark:bg-dynamic-neutral-900"],
      ref_key: "rootRef",
      ref: a
    }), {
      default: _(() => [
        d("div", {
          class: T(["w-full h-full", { "px-4 py-5 sm:p-6": t.padded }])
        }, [
          N(n.$slots, "default")
        ], 2)
      ]),
      _: 3
    }, 16, ["class"]));
  }
}), Ue = (e, o) => {
  const t = e.__vccOpts || e;
  for (const [a, n] of o)
    t[a] = n;
  return t;
}, Ve = {}, Ze = { class: "centered-container" };
function Fe(e, o) {
  return i(), u("div", Ze, [
    N(e.$slots, "default")
  ]);
}
const Bn = /* @__PURE__ */ Ue(Ve, [["render", Fe]]);
const We = { class: "flex flex-col input-component-wrapper" }, Qe = {
  key: 0,
  class: "flex justify-between mb-2"
}, Ge = ["for"], Ke = {
  key: 1,
  class: "text-sm text-dynamic-neutral-500 dark:text-dynamic-neutral-100",
  id: "email-optional"
}, Ye = { class: "relative flex flex-row items-start justify-start flex-grow" }, Xe = ["id", "placeholder", "type", "value"], Je = {
  key: 1,
  class: "mt-2 text-sm text-dynamic-neutral-500",
  id: "email-description"
}, jn = C({
  __name: "Input",
  props: {
    modelValue: {
      type: String,
      required: !1,
      default: () => ""
    },
    type: {
      type: String,
      default: () => "text"
    },
    placeholder: {
      type: String,
      default: () => ""
    },
    leadingIcon: {
      type: String,
      required: !1
    },
    trailingIcon: {
      type: String,
      required: !1
    },
    boldIcons: {
      type: Boolean,
      required: !1,
      default: () => !1
    },
    leadingButton: {
      type: Object,
      required: !1
    },
    trailingButton: {
      type: Object,
      required: !1
    },
    label: {
      type: String,
      required: !1
    },
    helperText: {
      type: String,
      required: !1
    },
    hintText: {
      type: String,
      required: !1
    },
    id: {
      type: String,
      required: !0
    },
    fullWidth: {
      type: Boolean,
      required: !1
    },
    hasTintOpacity: {
      type: Boolean,
      required: !1
    }
  },
  emits: ["update:modelValue"],
  setup(e, { emit: o }) {
    const t = (a) => {
      o("update:modelValue", a.target.value);
    };
    return (a, n) => (i(), u("div", We, [
      e.label || e.hintText ? (i(), u("div", Qe, [
        e.label ? (i(), u("label", {
          key: 0,
          for: e.id,
          class: "block text-sm font-medium text-dynamic-neutral-700 dark:text-dynamic-neutral-200"
        }, x(e.label), 9, Ge)) : y("", !0),
        e.hintText ? (i(), u("span", Ke, x(e.hintText), 1)) : y("", !0)
      ])) : y("", !0),
      d("div", {
        class: T(["flex flex-row input-wrapper", { "w-full": e.fullWidth }])
      }, [
        e.leadingButton ? (i(), u("button", {
          key: 0,
          onClick: n[0] || (n[0] = //@ts-ignore
          (...r) => e.leadingButton?.tapHandler && e.leadingButton?.tapHandler(...r)),
          type: "button",
          class: "relative inline-flex items-center px-4 py-2 -ml-px space-x-2 text-sm font-medium border text-dynamic-neutral-700 dark:text-dynamic-neutral-300 bg-dynamic-neutral-100 dark:bg-dynamic-neutral-800 hover:dark:bg-dynamic-neutral-700 border-dynamic-neutral-300 dark:border-dynamic-neutral-700 rounded-l-md hover:bg-dynamic-neutral-200 focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500"
        }, [
          e.leadingButton.icon ? (i(), u("i", {
            key: 0,
            class: T([
              e.leadingButton.icon,
              e.leadingButton.boldIcons ? "fas" : "far"
            ])
          }, null, 2)) : y("", !0),
          d("span", null, x(e.leadingButton.text), 1)
        ])) : y("", !0),
        d("div", Ye, [
          e.leadingIcon ? (i(), u("i", {
            key: 0,
            class: T(["leading-icon far", [e.leadingIcon, e.boldIcons ? "fas" : "far"]])
          }, null, 2)) : y("", !0),
          d("input", ae({
            "data-testid": "input-element",
            id: e.label,
            name: "search",
            class: [{
              "pl-10": !!e.leadingIcon,
              "pr-10": !!e.trailingIcon,
              "rounded-l-none": e.leadingButton,
              "rounded-r-none": e.trailingButton,
              "bg-tint-opacity-70": e.hasTintOpacity
            }, "block w-full px-3 py-2 text-sm border rounded-md bg-dynamic-neutral-50 placeholder-neutral-500 dark:placeholder-neutral-300 border-dynamic-neutral-200 dark:border-dynamic-neutral-700 dark:bg-dynamic-neutral-950 dark:bg-opacity-50 focus:outline-none text-dynamic-neutral-600 dark:text-dynamic-neutral-300 focus:placeholder-neutral-400 focus:ring-1 focus:ring-theme-600 focus:border-theme-600 dark:focus:ring-theme-500 dark:focus:border-theme-500 sm:text-sm"],
            placeholder: e.placeholder,
            type: e.type,
            value: e.modelValue,
            onInput: t
          }, a.$attrs), null, 16, Xe),
          e.trailingIcon ? (i(), u("i", {
            key: 1,
            class: T(["absolute text-dynamic-neutral-500 top-2.5 far right-2.5", e.trailingIcon])
          }, null, 2)) : y("", !0)
        ]),
        e.trailingButton ? (i(), u("button", {
          key: 1,
          onClick: n[1] || (n[1] = //@ts-ignore
          (...r) => e.trailingButton?.tapHandler && e.trailingButton?.tapHandler(...r)),
          type: "button",
          class: "relative inline-flex items-center px-4 py-2 -ml-px space-x-2 text-sm font-medium border text-dynamic-neutral-700 dark:text-dynamic-neutral-300 bg-dynamic-neutral-100 dark:bg-dynamic-neutral-800 border-dynamic-neutral-300 dark:border-dynamic-neutral-700 rounded-r-md hover:bg-dynamic-neutral-200 hover:dark:bg-dynamic-neutral-700 focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500"
        }, [
          e.trailingButton.icon ? (i(), u("i", {
            key: 0,
            class: T([
              e.trailingButton.icon,
              e.trailingButton.boldIcons ? "fas" : "far"
            ])
          }, null, 2)) : y("", !0),
          d("span", null, x(e.trailingButton.text), 1)
        ])) : y("", !0)
      ], 2),
      e.helperText ? (i(), u("p", Je, x(e.helperText), 1)) : y("", !0)
    ]));
  }
}), et = { class: "flex justify-between xs:flex-row text-dynamic-neutral-500 dark:text-dynamic-neutral-400" }, tt = { class: "relative flex justify-between xs:flex-row -left-2 xs:-left-3" }, nt = {
  key: 0,
  class: "far fa-heart"
}, ot = {
  key: 1,
  class: "fas fa-heart"
}, at = { key: 2 }, rt = /* @__PURE__ */ d("i", { class: "far fa-arrow-up-from-bracket" }, null, -1), lt = { key: 0 }, st = /* @__PURE__ */ d("i", { class: "far fa-comments" }, null, -1), it = { class: "flex flex-row" }, dt = { key: 0 }, ut = /* @__PURE__ */ d("span", { class: "hidden xs:!flex" }, "replies", -1), ct = {
  key: 0,
  class: "fad fa-bookmark"
}, pt = {
  key: 1,
  class: "far fa-bookmark"
}, ie = C({
  __name: "PostActionSection",
  props: {
    post: {},
    bookmarked: { type: Boolean },
    reacted: { type: Boolean }
  },
  emits: ["reactionTap", "replyTap", "shareTap", "bookmarkTap"],
  setup(e, { emit: o }) {
    return (t, a) => (i(), u("div", et, [
      d("span", tt, [
        d("a", {
          "data-testid": "reaction-button",
          onClick: a[0] || (a[0] = O((n) => o("reactionTap"), ["stop"])),
          class: T([{
            "text-red-500": t.reacted,
            "hover:text-red-400": !t.reacted
          }, "flex items-center px-3 py-1 space-x-2 transition-all bg-red-200 bg-opacity-0 rounded-full xs:px-4 xs:py-2 xs:justify-center hover:bg-opacity-25 hover:text-red-500"])
        }, [
          t.reacted ? (i(), u("i", ot)) : (i(), u("i", nt)),
          t.post.reaction_count ? (i(), u("span", at, x(t.post.reaction_count), 1)) : y("", !0)
        ], 2),
        d("a", {
          "data-testid": "share-button",
          onClick: a[1] || (a[1] = O((n) => o("shareTap"), ["stop"])),
          class: "flex items-center px-3 py-1 space-x-2 transition-all bg-green-200 bg-opacity-0 rounded-full xs:px-4 xs:py-2 xs:justify-center hover:bg-opacity-25 hover:text-green-500"
        }, [
          rt,
          t.post.share_count ? (i(), u("span", lt, x(t.post.share_count), 1)) : y("", !0)
        ]),
        d("a", {
          "data-testid": "reply-button",
          onClick: a[2] || (a[2] = O((n) => o("replyTap"), ["stop"])),
          class: "relative flex items-center px-3 py-1 space-x-2 transition-all bg-orange-200 bg-opacity-0 rounded-full xs:px-4 xs:py-2 xs:justify-center hover:bg-opacity-25 hover:text-orange-500"
        }, [
          st,
          d("span", it, [
            t.post.reply_count ? (i(), u("span", dt, x(t.post.reply_count) + " ", 1)) : y("", !0),
            ut
          ])
        ])
      ]),
      d("a", {
        "data-testid": "bookmark-button",
        onClick: a[3] || (a[3] = O((n) => o("bookmarkTap"), ["stop"])),
        class: T([{
          "text-blue-400": t.bookmarked,
          "hover:text-blue-400": !t.bookmarked
        }, "relative flex items-center justify-center px-3 py-1 space-x-2 transition-all bg-blue-300 bg-opacity-0 rounded-full xs:px-4 xs:py-2 hover:bg-opacity-25 hover:text-blue-400 -right-3"])
      }, [
        t.bookmarked ? (i(), u("i", ct)) : (i(), u("i", pt))
      ], 2)
    ]));
  }
});
var K;
const de = typeof window < "u", ft = (e) => typeof e == "string", ue = () => {
}, mt = de && ((K = window?.navigator) == null ? void 0 : K.userAgent) && /iP(ad|hone|od)/.test(window.navigator.userAgent);
function ce(e) {
  return typeof e == "function" ? e() : E(e);
}
function yt(e) {
  return e;
}
function ht(e) {
  return Pe() ? (Oe(e), !0) : !1;
}
function L(e) {
  var o;
  const t = ce(e);
  return (o = t?.$el) != null ? o : t;
}
const pe = de ? window : void 0;
function V(...e) {
  let o, t, a, n;
  if (ft(e[0]) || Array.isArray(e[0]) ? ([t, a, n] = e, o = pe) : [o, t, a, n] = e, !o)
    return ue;
  Array.isArray(t) || (t = [t]), Array.isArray(a) || (a = [a]);
  const r = [], l = () => {
    r.forEach((g) => g()), r.length = 0;
  }, p = (g, w, f, h) => (g.addEventListener(w, f, h), () => g.removeEventListener(w, f, h)), m = re(() => [L(o), ce(n)], ([g, w]) => {
    l(), g && r.push(...t.flatMap((f) => a.map((h) => p(g, f, h, w))));
  }, { immediate: !0, flush: "post" }), b = () => {
    m(), l();
  };
  return ht(b), b;
}
let Y = !1;
function fe(e, o, t = {}) {
  const { window: a = pe, ignore: n = [], capture: r = !0, detectIframe: l = !1 } = t;
  if (!a)
    return;
  mt && !Y && (Y = !0, Array.from(a.document.body.children).forEach((f) => f.addEventListener("click", ue)));
  let p = !0;
  const m = (f) => n.some((h) => {
    if (typeof h == "string")
      return Array.from(a.document.querySelectorAll(h)).some((v) => v === f.target || f.composedPath().includes(v));
    {
      const v = L(h);
      return v && (f.target === v || f.composedPath().includes(v));
    }
  }), g = [
    V(a, "click", (f) => {
      const h = L(e);
      if (!(!h || h === f.target || f.composedPath().includes(h))) {
        if (f.detail === 0 && (p = !m(f)), !p) {
          p = !0;
          return;
        }
        o(f);
      }
    }, { passive: !0, capture: r }),
    V(a, "pointerdown", (f) => {
      const h = L(e);
      h && (p = !f.composedPath().includes(h) && !m(f));
    }, { passive: !0 }),
    l && V(a, "blur", (f) => {
      var h;
      const v = L(e);
      ((h = a.document.activeElement) == null ? void 0 : h.tagName) === "IFRAME" && !v?.contains(a.document.activeElement) && o(f);
    })
  ].filter(Boolean);
  return () => g.forEach((f) => f());
}
const X = typeof globalThis < "u" ? globalThis : typeof window < "u" ? window : typeof global < "u" ? global : typeof self < "u" ? self : {}, J = "__vueuse_ssr_handlers__";
X[J] = X[J] || {};
var ee;
(function(e) {
  e.UP = "UP", e.RIGHT = "RIGHT", e.DOWN = "DOWN", e.LEFT = "LEFT", e.NONE = "NONE";
})(ee || (ee = {}));
var gt = Object.defineProperty, te = Object.getOwnPropertySymbols, bt = Object.prototype.hasOwnProperty, kt = Object.prototype.propertyIsEnumerable, ne = (e, o, t) => o in e ? gt(e, o, { enumerable: !0, configurable: !0, writable: !0, value: t }) : e[o] = t, vt = (e, o) => {
  for (var t in o || (o = {}))
    bt.call(o, t) && ne(e, t, o[t]);
  if (te)
    for (var t of te(o))
      kt.call(o, t) && ne(e, t, o[t]);
  return e;
};
const xt = {
  easeInSine: [0.12, 0, 0.39, 0],
  easeOutSine: [0.61, 1, 0.88, 1],
  easeInOutSine: [0.37, 0, 0.63, 1],
  easeInQuad: [0.11, 0, 0.5, 0],
  easeOutQuad: [0.5, 1, 0.89, 1],
  easeInOutQuad: [0.45, 0, 0.55, 1],
  easeInCubic: [0.32, 0, 0.67, 0],
  easeOutCubic: [0.33, 1, 0.68, 1],
  easeInOutCubic: [0.65, 0, 0.35, 1],
  easeInQuart: [0.5, 0, 0.75, 0],
  easeOutQuart: [0.25, 1, 0.5, 1],
  easeInOutQuart: [0.76, 0, 0.24, 1],
  easeInQuint: [0.64, 0, 0.78, 0],
  easeOutQuint: [0.22, 1, 0.36, 1],
  easeInOutQuint: [0.83, 0, 0.17, 1],
  easeInExpo: [0.7, 0, 0.84, 0],
  easeOutExpo: [0.16, 1, 0.3, 1],
  easeInOutExpo: [0.87, 0, 0.13, 1],
  easeInCirc: [0.55, 0, 1, 0.45],
  easeOutCirc: [0, 0.55, 0.45, 1],
  easeInOutCirc: [0.85, 0, 0.15, 1],
  easeInBack: [0.36, 0, 0.66, -0.56],
  easeOutBack: [0.34, 1.56, 0.64, 1],
  easeInOutBack: [0.68, -0.6, 0.32, 1.6]
};
vt({
  linear: yt
}, xt);
const wt = {
  key: 0,
  class: "absolute w-full h-full"
}, $t = /* @__PURE__ */ d("linearGradient", {
  id: "linearColors",
  x1: "0",
  y1: "0",
  x2: "1",
  y2: "1"
}, [
  /* @__PURE__ */ d("stop", {
    offset: "50%",
    class: "stop-theme-color"
  }),
  /* @__PURE__ */ d("stop", {
    offset: "100%",
    class: "stop-theme-accent-color"
  })
], -1), Tt = ["r"], zt = ["src", "alt"], Ct = /* @__PURE__ */ d("span", null, [
  /* @__PURE__ */ d("i", { class: "far fa-user" })
], -1), Pt = [
  Ct
], me = C({
  __name: "ProfilePhoto",
  props: {
    imageUrl: { default: "" },
    size: { default: "md" },
    accessibleText: {},
    placeholderText: {},
    shape: { default: "circle" },
    showHalo: { type: Boolean, default: !1 }
  },
  emits: ["tap"],
  setup(e, { emit: o }) {
    const t = e, a = B(() => t.size === "xs" ? 10 : t.size === "sm" ? 15 : t.size === "md" ? 19 : t.size === "lg" ? 30 : t.size === "xl" ? 45 : 19);
    return (n, r) => (i(), u("div", {
      onClick: r[0] || (r[0] = (l) => o("tap")),
      class: T(["box-border relative flex items-center justify-center flex-shrink-0", {
        "w-6 h-6": n.size == "xs",
        "w-8 h-8": n.size === "sm",
        "w-10 h-10": n.size === "md",
        "w-16 h-16": n.size === "lg",
        "w-24 h-24": n.size === "xl"
      }])
    }, [
      n.shape === "circle" && n.showHalo ? (i(), u("svg", wt, [
        $t,
        d("circle", {
          r: a.value,
          cx: "50%",
          cy: "50%",
          "stroke-width": "1.5",
          fill: "none",
          stroke: "url(#linearColors)"
        }, null, 8, Tt)
      ])) : y("", !0),
      d("div", {
        class: T(["relative flex", {
          "w-4 h-4": n.size == "xs" && n.showHalo,
          "w-6 h-6": n.size == "xs" && !n.showHalo,
          "w-6 h-6 ": n.size === "sm" && n.showHalo,
          "w-8 h-8": n.size === "sm" && !n.showHalo,
          "w-8 h-8 ": n.size === "md" && n.showHalo,
          "w-10 h-10": n.size === "md" && !n.showHalo,
          "w-14 h-14 ": n.size === "lg" && n.showHalo,
          "w-16 h-16": n.size === "lg" && !n.showHalo,
          "w-20 h-20": n.size === "xl" && n.showHalo,
          "w-24 h-24": n.size === "xl" && !n.showHalo
        }])
      }, [
        n.imageUrl ? (i(), u("img", {
          key: 0,
          class: T(["object-cover w-full h-full", {
            "rounded-full": n.shape === "circle",
            "rounded-sm": n.shape === "square" && (n.size === "sm" || n.size == "xs"),
            "rounded-md": n.shape === "square" && n.size === "md",
            "rounded-lg": n.shape === "square" && n.size === "lg",
            "rounded-xl": n.shape === "square" && n.size === "xl"
          }]),
          src: n.imageUrl,
          alt: n.accessibleText ?? "",
          loading: "lazy"
        }, null, 10, zt)) : n.placeholderText ? (i(), u("div", {
          key: 1,
          class: T([{
            "text-xs": n.size === "sm" || n.size === "xs",
            "text-lg": n.size === "md",
            "text-xl": n.size === "lg",
            "text-3xl": n.size === "xl",
            "rounded-full": n.shape === "circle",
            "rounded-sm": n.shape === "square" && (n.size === "sm" || n.size == "xs"),
            "rounded-md": n.shape === "square" && n.size === "md",
            "rounded-lg": n.shape === "square" && n.size === "lg",
            "rounded-xl": n.shape === "square" && n.size === "xl"
          }, "flex items-center justify-center w-full h-full text-theme-aware-neutral-500 bg-dynamic-neutral-100 bg-theme-500"])
        }, [
          d("span", null, x(n.size !== "xs" && n.size !== "sm" ? n.placeholderText : n.placeholderText.charAt(0)), 1)
        ], 2)) : (i(), u("div", {
          key: 2,
          class: T([{
            "text-xs": n.size === "sm" || n.size == "xs",
            "text-lg": n.size === "md",
            "text-xl": n.size === "lg",
            "text-3xl": n.size === "xl",
            "rounded-full": n.shape === "circle",
            "rounded-sm": n.shape === "square" && (n.size === "sm" || n.size == "xs"),
            "rounded-md": n.shape === "square" && n.size === "md",
            "rounded-lg": n.shape === "square" && n.size === "lg",
            "rounded-xl": n.shape === "square" && n.size === "xl"
          }, "flex items-center justify-center w-full h-full bg-theme-500 text-dynamic-neutral-100"])
        }, Pt, 2))
      ], 2)
    ], 2));
  }
}), Ot = { class: "relative flex flex-row items-center justify-between space-x-2 text-sm font-medium dark:text-dynamic-neutral-200 text-dynamic-neutral-900" }, Bt = { class: "box-border flex flex-col flex-grow pr-3 space-y-1" }, jt = { class: "flex flex-col xs:block" }, St = { class: "text-dynamic-neutral-500 dark:text-dynamic-neutral-400" }, Et = { key: 1 }, At = { class: "text-dynamic-neutral-400 dark:text-dynamic-neutral-400" }, It = ["onClick"], Lt = /* @__PURE__ */ d("i", { class: "far fa-ellipsis" }, null, -1), Rt = [
  Lt
], ye = C({
  __name: "PostHeader",
  props: {
    user: {},
    location: {},
    network: {},
    readableDate: {},
    bookmarked: { type: Boolean, default: !1 },
    withProfile: { type: Boolean, default: !1 }
  },
  emits: [
    "bookmarkTap",
    "contextMenuTap",
    "userTap",
    "networkTap"
  ],
  setup(e, { emit: o }) {
    const t = A("appConfig", {});
    function a(r) {
      r.stopImmediatePropagation();
      const { top: l, left: p } = n(r.target);
      o("contextMenuTap", { top: l, left: p });
    }
    function n(r) {
      const l = r.getBoundingClientRect(), p = document.body, m = document.documentElement;
      window.pageYOffset || m.scrollTop || p.scrollTop, window.pageXOffset || m.scrollLeft || p.scrollLeft;
      const b = m.clientTop || p.clientTop || 0, g = m.clientLeft || p.clientLeft || 0, w = l.top + b, f = l.left + g;
      return {
        top: Math.round(w) + l.height + 2,
        left: Math.round(f) - (265 - (l.width + 5)),
        width: l.width,
        height: l.height
      };
    }
    return (r, l) => {
      const p = me;
      return i(), u("div", null, [
        d("p", Ot, [
          r.withProfile ? (i(), j(p, {
            key: 0,
            "placeholder-text": r.user.acronym,
            "image-url": r.user.profile_photo ? E(t).content_url + "profile/small/" + r.user.profile_photo.filename : "",
            "show-halo": "",
            class: "hidden bg-opacity-100 xs:flex"
          }, null, 8, ["placeholder-text", "image-url"])) : y("", !0),
          d("span", Bt, [
            d("span", jt, [
              d("a", {
                "data-testid": "user-name",
                onClick: l[0] || (l[0] = O((m) => o("userTap"), ["stop"])),
                href: "#",
                class: "inline break-words"
              }, x(r.user.name), 1),
              r.withProfile ? y("", !0) : (i(), u("a", {
                key: 0,
                "data-testid": "user-username",
                onClick: l[1] || (l[1] = O((m) => o("userTap"), ["stop"])),
                class: "text-dynamic-neutral-500 dark:text-dynamic-neutral-400",
                href: "#"
              }, "@" + x(r.user.username), 1))
            ]),
            d("span", St, [
              r.withProfile ? (i(), u("a", {
                key: 0,
                "data-testid": "user-username",
                onClick: l[2] || (l[2] = O((m) => o("userTap"), ["stop"])),
                class: "text-dynamic-neutral-500 dark:text-dynamic-neutral-400",
                href: "#"
              }, "@" + x(r.user.username), 1)) : y("", !0),
              d("a", {
                "data-testid": "user-username",
                class: "text-dynamic-neutral-500 dark:text-dynamic-neutral-400",
                onClick: l[3] || (l[3] = O((m) => o("networkTap"), ["stop"])),
                href: "#"
              }, x(r.network ? r.withProfile ? " • " + r.network.title : r.network.title + " • " : ""), 1),
              r.withProfile ? y("", !0) : (i(), u("span", Et, x(r.readableDate), 1))
            ])
          ]),
          d("span", At, [
            d("a", {
              "data-testid": "context-button",
              onClick: O(a, ["stop"]),
              class: "absolute items-center justify-center hidden px-3 py-1 space-x-2 text-lg transition-all rounded-full -top-2 -right-3 xs:px-4 xs:py-2 xs:flex hover:bg-dynamic-neutral-200 dark:hover:bg-dynamic-neutral-700 hover:text-dynamic-neutral-400"
            }, Rt, 8, It)
          ])
        ])
      ]);
    };
  }
}), Ht = { class: "space-y-2 md:space-y-1" }, Dt = {
  key: 0,
  class: "h-[1px] mx-2 bg-dynamic-neutral-100 dark:bg-dynamic-neutral-800"
}, Nt = { class: "relative flex flex-col" }, qt = {
  key: 0,
  class: "text-lg"
}, Mt = { key: 1 }, _t = ["onClick"], Ut = { class: "mr-2" }, he = C({
  __name: "ContextMenu",
  props: {
    config: {},
    verticalPosition: {},
    horizontalPosition: {}
  },
  emits: ["closeContextTap"],
  setup(e, { emit: o }) {
    const t = e;
    function a(n) {
      n.stopPropagation(), o("closeContextTap");
    }
    return (n, r) => (i(), u("div", {
      class: "fixed top-0 left-0 z-50 flex flex-col items-center justify-center w-full h-full glass-blur bg-dynamic-neutral-dark/20 md:bg-dynamic-neutral-dark/0",
      onClick: a
    }, [
      q(_e, {
        padded: !1,
        style: Be({
          "--context-position-x": t.horizontalPosition + "px",
          "--context-position-y": t.verticalPosition + "px"
        }),
        class: "!absolute md:!left-[var(--context-position-x)] md:!top-[var(--context-position-y)] md:!fixed bottom-0 left-0 w-full max-w-full rounded-t-lg rounded-b-none md:rounded-md p-2 md:p-1 pt-3 pb-5-safe overflow-scroll md:bottom-auto md:left-auto md:w-64 text-dynamic-neutral-600 dark:text-dynamic-neutral-300 !shadow-xl md:border border-dynamic-neutral-100 dark:border-dynamic-neutral-800 dark:shadow-black"
      }, {
        default: _(() => [
          d("div", Ht, [
            n.config && n.config.lists ? (i(!0), u(S, { key: 0 }, R(n.config.lists, (l, p) => (i(), u(S, { key: p }, [
              p !== 0 ? (i(), u("div", Dt)) : y("", !0),
              d("div", Nt, [
                l.label ? (i(), u("h4", qt, x(l.label), 1)) : y("", !0),
                l.description ? (i(), u("section", Mt, x(l.description), 1)) : y("", !0),
                d("section", {
                  class: T([{
                    "flex-col space-y-1": l.direction !== "horizontal",
                    "flex-row pb-3": l.direction === "horizontal"
                  }, "relative flex w-full max-w-full overflow-scroll"])
                }, [
                  (i(!0), u(S, null, R(l.items, (m, b) => (i(), u(S, { key: b }, [
                    m.show ? (i(), u("button", {
                      key: 0,
                      class: T([{
                        "flex-col rounded-md items-center min-w-[8rem]": l.direction === "horizontal",
                        "flex-row rounded-md": l.direction !== "horizontal"
                      }, "flex px-4 py-2 text-sm cursor-pointer xs:text-lg lg:text-base hover:bg-dynamic-neutral-100 dark:hover:bg-dynamic-neutral-800"]),
                      onClick: m.action
                    }, [
                      d("span", Ut, [
                        d("i", {
                          class: T(m.icon)
                        }, null, 2)
                      ]),
                      d("span", null, x(m.label), 1)
                    ], 10, _t)) : y("", !0)
                  ], 64))), 128))
                ], 2)
              ])
            ], 64))), 128)) : y("", !0)
          ])
        ]),
        _: 1
      }, 8, ["style"])
    ]));
  }
});
const Vt = /* @__PURE__ */ d("i", { class: "far fa-comments" }, null, -1), Zt = { class: "flex flex-row max-w-full p-2 bg-opacity-0 cursor-pointer xs:space-x-3 xs:p-4 bg-neutral-400 pointer-fine:dark:hover:bg-opacity-10 pointer-fine:hover:bg-opacity-5 border-spacing-0" }, Ft = { class: "post-content" }, Wt = {
  "data-testid": "post-text",
  class: "xs:text-base text-dynamic-neutral-700 dark:text-dynamic-neutral-300"
}, Qt = {
  key: 1,
  class: "relative inline-block scroll-pl-16 pl-16 space-x-2 overflow-x-scroll h-64 whitespace-nowrap snap-x -left-16 w-[calc(100%+4.8rem)] align-middle hide-scrollbar"
}, Gt = ["src"], Sn = C({
  __name: "PostFeedItem",
  props: {
    post: {},
    user: {},
    bookmarked: { type: Boolean },
    reacted: { type: Boolean },
    photos: {},
    links: {}
  },
  emits: [
    "bookmark-tap",
    "reaction-tap",
    "reply-tap",
    "share-tap",
    "post-tap",
    "context-menu-tap",
    "pin-tap",
    "delete-tap",
    "copy-link-tap",
    "report-tap",
    "reply-to-tap",
    "user-tap",
    "network-tap"
  ],
  setup(e, { emit: o }) {
    const t = e, a = A("auth_id", null), n = A("appConfig", {}), r = P({ x: 0, y: 0 }), l = B(() => ({
      template: t.post.text.replace(/</g, "&lt;").replace(/>/g, "&gt;").replace(/{/g, "&lcub;").replace(/}/g, "&rcub;").replace(/\[/g, "&lbrack").replace(/\]/g, "&rbrack").replace(/\n/g, "<br/>").replace(/!!([^!]+)!!/g, '<span class="italic">$1</span>').replace(/__([^_]+)__/g, '<span class="underline">$1</span>').replace(/~~([^~]+)~~/g, '<span class="line-through">$1</span>').replace(/\*\*([^*]+)\*\*/g, '<span class="font-bold">$1</span>').replace(
        /``([^`]+)``/g,
        '<span class="border rounded-md py-0.5 px-1 border-theme-500 bg-theme-100 text-theme-500">$1</span>'
      ).replace(
        /(([a-zA-Z0-9]+:\/\/)?([a-zA-Z0-9_]+:[a-zA-Z0-9_]+@)?([a-zA-Z0-9.-]+\.[A-Za-z]{2,4})(:[0-9]+)?((\/.{0,14}).*)?)/g,
        '<a href="$1" clas v-on:click.stop rel="noreferrer" target="_blank">$2$4$5...</a>'
      ).replace(
        /#([a-zA-Z0-9_]+)/g,
        `<RouterLink v-on:click.stop v-bind:to="{name: 'tag', params: { tagId: '$1' }}" class="font-semibold text-theme-600 dark:text-theme-300 tint-rate-75">#$1</RouterLink>`
      ).replace(
        // /(?<!\w)@([a-zA-Z0-9_]+)(?!\w|$)/g,
        /@(\w+)\b/g,
        `<RouterLink v-on:click.stop v-bind:to="{name: 'profile', params: { username: '$1'}}" class="font-semibold text-theme-600 dark:text-theme-300 tint-rate-75">@$1</RouterLink>`
      ),
      name: "PostTextContent"
    })), p = P(!1), m = P(null);
    function b() {
      console.log("emitting pin Post event..."), o("pin-tap", t.post.id);
    }
    function g() {
      console.log("emitting delete Post event..."), confirm(
        `Are you sure you want to delete this post ${t.post.id}`
      ) && o("delete-tap", t.post.id);
    }
    function w() {
      console.log("emitting report Post event..."), o("report-tap", t.post.id);
    }
    function f() {
      console.log("emitting copy link event..."), o("copy-link-tap", t.post.id), navigator.clipboard && navigator.clipboard.writeText(
        window.location.origin + "/post/" + t.post.id
      );
    }
    function h(s) {
      o("reply-to-tap", s.id);
    }
    async function v() {
      o("share-tap", t.post.id);
      const s = {
        title: `Post by ${t.user?.name}`,
        text: t.post.text,
        url: window.location.origin + "/post/" + t.post.id
      };
      try {
        await window.navigator.share(s);
      } catch (c) {
        console.log(c);
      }
    }
    function $() {
      p.value = !p.value;
    }
    fe(m.value, () => console.log("CLikc outside"));
    const k = B(() => ({
      lists: [
        {
          items: [
            {
              label: "Pin",
              icon: "fas fa-thumbtack",
              action: b,
              show: a === t.post.user_id
            },
            {
              label: "Link",
              icon: "fas fa-link",
              action: f,
              show: !0
            },
            {
              label: "Share via...",
              icon: "fas fa-share-alt",
              action: v,
              show: "share" in navigator
            },
            {
              label: "Delete",
              icon: "fas fa-trash",
              action: g,
              show: a === t.post.user_id
            },
            {
              label: "Report",
              icon: "fas fa-flag",
              action: w,
              show: !0
            }
          ]
        },
        {
          items: [
            {
              label: "Cancel",
              icon: "fas fa-times",
              show: !0,
              action: () => console.log(`cancel link ${t.post.id}`)
            }
          ]
        }
      ]
    }));
    return (s, c) => (i(), u(S, null, [
      s.post.reply_to_id !== null && s.post.reply_to ? (i(), u("a", {
        key: 0,
        onClick: c[0] || (c[0] = O((z) => h(s.post.reply_to), ["stop"])),
        class: "relative flex items-center px-4 py-2 space-x-1 text-xs font-medium leading-relaxed border-b cursor-pointer text-dynamic-neutral-400 hover:text-dynamic-neutral-500 dark:text-dynamic-neutral-400 dark:hover:text-dynamic-neutral-300 border-dynamic-neutral-100 dark:border-dynamic-neutral-800"
      }, [
        Vt,
        d("span", null, "Replying to @" + x(s.post.reply_to.user?.username), 1)
      ])) : y("", !0),
      d("a", {
        onClick: c[9] || (c[9] = O((z) => o("post-tap"), ["stop"])),
        class: "relative post-element"
      }, [
        (i(), j(le, { to: "body" }, [
          p.value ? (i(), j(he, {
            key: 0,
            "horizontal-position": r.value.x,
            "vertical-position": r.value.y,
            config: k.value,
            onCloseContextTap: c[1] || (c[1] = (z) => p.value = !1),
            ref_key: "contextElementRef",
            ref: m
          }, null, 8, ["horizontal-position", "vertical-position", "config"])) : y("", !0)
        ])),
        d("div", Zt, [
          q(me, {
            "placeholder-text": s.user.acronym,
            class: "hidden bg-opacity-100 xs:flex",
            "show-halo": "",
            "image-url": s.user.profile_photo ? E(n).content_url + "profile/small/" + s.user.profile_photo.filename : ""
          }, null, 8, ["placeholder-text", "image-url"]),
          d("div", Ft, [
            s.post && s.user ? (i(), j(ye, {
              key: 0,
              user: s.user,
              "readable-date": s.post.created_at_readable ?? "",
              bookmarked: !!s.bookmarked,
              network: s.post.network ?? void 0,
              onBookmarkTap: c[2] || (c[2] = () => o("bookmark-tap", s.post.id)),
              onContextMenuTap: c[3] || (c[3] = (z) => {
                r.value.x = z.left, r.value.y = z.top, $();
              }),
              onUserTap: c[4] || (c[4] = () => o("user-tap")),
              onNetworkTap: c[5] || (c[5] = () => o("network-tap"))
            }, null, 8, ["user", "readable-date", "bookmarked", "network"])) : y("", !0),
            d("p", Wt, [
              (i(), j(M(l.value)))
            ]),
            s.post.photos?.length ? (i(), u("div", Qt, [
              (i(!0), u(S, null, R(s.post.photos || [], (z, I) => (i(), u("div", {
                key: I,
                class: "box-border relative inline-block w-auto h-full overflow-hidden align-middle rounded-lg bg-dynamic-neutral-500/20 snap-start"
              }, [
                d("img", {
                  class: "inline-flex object-cover object-center w-auto h-full max-h-full",
                  src: z ? E(n).content_url + "posts/large/" + z.filename : "",
                  alt: ""
                }, null, 8, Gt)
              ]))), 128))
            ])) : y("", !0),
            N(s.$slots, "default"),
            N(s.$slots, "links"),
            q(ie, {
              reacted: !!s.reacted,
              bookmarked: !!s.bookmarked,
              onBookmarkTap: c[6] || (c[6] = () => o("bookmark-tap", s.post.id)),
              onReactionTap: c[7] || (c[7] = () => o("reaction-tap", s.post.id)),
              onReplyTap: c[8] || (c[8] = () => o("reply-tap", s.post.id)),
              onShareTap: v,
              post: s.post
            }, null, 8, ["reacted", "bookmarked", "post"])
          ])
        ])
      ])
    ], 64));
  }
});
function ge(e, o, ...t) {
  if (e in o) {
    let n = o[e];
    return typeof n == "function" ? n(...t) : n;
  }
  let a = new Error(`Tried to handle "${e}" but there is no handler defined. Only defined handlers are: ${Object.keys(o).map((n) => `"${n}"`).join(", ")}.`);
  throw Error.captureStackTrace && Error.captureStackTrace(a, ge), a;
}
var Kt = ((e) => (e[e.None = 0] = "None", e[e.RenderStrategy = 1] = "RenderStrategy", e[e.Static = 2] = "Static", e))(Kt || {}), Yt = ((e) => (e[e.Unmount = 0] = "Unmount", e[e.Hidden = 1] = "Hidden", e))(Yt || {});
function H({ visible: e = !0, features: o = 0, ourProps: t, theirProps: a, ...n }) {
  var r;
  let l = ke(a, t), p = Object.assign(n, { props: l });
  if (e || o & 2 && l.static)
    return Z(p);
  if (o & 1) {
    let m = (r = l.unmount) == null || r ? 0 : 1;
    return ge(m, { 0() {
      return null;
    }, 1() {
      return Z({ ...n, props: { ...l, hidden: !0, style: { display: "none" } } });
    } });
  }
  return Z(p);
}
function Z({ props: e, attrs: o, slots: t, slot: a, name: n }) {
  var r, l;
  let { as: p, ...m } = ve(e, ["unmount", "static"]), b = (r = t.default) == null ? void 0 : r.call(t, a), g = {};
  if (a) {
    let w = !1, f = [];
    for (let [h, v] of Object.entries(a))
      typeof v == "boolean" && (w = !0), v === !0 && f.push(h);
    w && (g["data-headlessui-state"] = f.join(" "));
  }
  if (p === "template") {
    if (b = be(b ?? []), Object.keys(m).length > 0 || Object.keys(o).length > 0) {
      let [w, ...f] = b ?? [];
      if (!Jt(w) || f.length > 0)
        throw new Error(['Passing props on "template"!', "", `The current component <${n} /> is rendering a "template".`, "However we need to passthrough the following props:", Object.keys(m).concat(Object.keys(o)).map(($) => $.trim()).filter(($, k, s) => s.indexOf($) === k).sort(($, k) => $.localeCompare(k)).map(($) => `  - ${$}`).join(`
`), "", "You can apply a few solutions:", ['Add an `as="..."` prop, to ensure that we render an actual element instead of a "template".', "Render a single element as the child so that we can forward the props onto that element."].map(($) => `  - ${$}`).join(`
`)].join(`
`));
      let h = ke((l = w.props) != null ? l : {}, m), v = je(w, h);
      for (let $ in h)
        $.startsWith("on") && (v.props || (v.props = {}), v.props[$] = h[$]);
      return v;
    }
    return Array.isArray(b) && b.length === 1 ? b[0] : b;
  }
  return F(p, Object.assign({}, m, g), { default: () => b });
}
function be(e) {
  return e.flatMap((o) => o.type === S ? be(o.children) : [o]);
}
function ke(...e) {
  if (e.length === 0)
    return {};
  if (e.length === 1)
    return e[0];
  let o = {}, t = {};
  for (let a of e)
    for (let n in a)
      n.startsWith("on") && typeof a[n] == "function" ? (t[n] != null || (t[n] = []), t[n].push(a[n])) : o[n] = a[n];
  if (o.disabled || o["aria-disabled"])
    return Object.assign(o, Object.fromEntries(Object.keys(t).map((a) => [a, void 0])));
  for (let a in t)
    Object.assign(o, { [a](n, ...r) {
      let l = t[a];
      for (let p of l) {
        if (n instanceof Event && n.defaultPrevented)
          return;
        p(n, ...r);
      }
    } });
  return o;
}
function Xt(e) {
  let o = Object.assign({}, e);
  for (let t in o)
    o[t] === void 0 && delete o[t];
  return o;
}
function ve(e, o = []) {
  let t = Object.assign({}, e);
  for (let a of o)
    a in t && delete t[a];
  return t;
}
function Jt(e) {
  return e == null ? !1 : typeof e.type == "string" || typeof e.type == "object" || typeof e.type == "function";
}
let en = 0;
function tn() {
  return ++en;
}
function G() {
  return tn();
}
var W = ((e) => (e.Space = " ", e.Enter = "Enter", e.Escape = "Escape", e.Backspace = "Backspace", e.Delete = "Delete", e.ArrowLeft = "ArrowLeft", e.ArrowUp = "ArrowUp", e.ArrowRight = "ArrowRight", e.ArrowDown = "ArrowDown", e.Home = "Home", e.End = "End", e.PageUp = "PageUp", e.PageDown = "PageDown", e.Tab = "Tab", e))(W || {});
function D(e) {
  var o;
  return e == null || e.value == null ? null : (o = e.value.$el) != null ? o : e.value;
}
function oe(e, o) {
  if (e)
    return e;
  let t = o ?? "button";
  if (typeof t == "string" && t.toLowerCase() === "button")
    return "button";
}
function nn(e, o) {
  let t = P(oe(e.value.type, e.value.as));
  return U(() => {
    t.value = oe(e.value.type, e.value.as);
  }), Se(() => {
    var a;
    t.value || D(o) && D(o) instanceof HTMLButtonElement && !((a = D(o)) != null && a.hasAttribute("type")) && (t.value = "button");
  }), t;
}
var xe = ((e) => (e[e.None = 1] = "None", e[e.Focusable = 2] = "Focusable", e[e.Hidden = 4] = "Hidden", e))(xe || {});
let on = C({ name: "Hidden", props: { as: { type: [Object, String], default: "div" }, features: { type: Number, default: 1 } }, setup(e, { slots: o, attrs: t }) {
  return () => {
    let { features: a, ...n } = e, r = { "aria-hidden": (a & 2) === 2 ? !0 : void 0, style: { position: "fixed", top: 1, left: 1, width: 1, height: 0, padding: 0, margin: -1, overflow: "hidden", clip: "rect(0, 0, 0, 0)", whiteSpace: "nowrap", borderWidth: "0", ...(a & 4) === 4 && (a & 2) !== 2 && { display: "none" } } };
    return H({ ourProps: r, theirProps: n, slot: {}, attrs: t, slots: o, name: "Hidden" });
  };
} });
function an(e) {
  var o;
  let t = (o = e?.form) != null ? o : e.closest("form");
  if (t) {
    for (let a of t.elements)
      if (a.tagName === "INPUT" && a.type === "submit" || a.tagName === "BUTTON" && a.type === "submit" || a.nodeName === "INPUT" && a.type === "image") {
        a.click();
        return;
      }
  }
}
function rn(e, o, t) {
  let a = P(t?.value), n = B(() => e.value !== void 0);
  return [B(() => n.value ? e.value : a.value), function(r) {
    return n.value || (a.value = r), o?.(r);
  }];
}
let we = Symbol("DescriptionContext");
function ln() {
  let e = A(we, null);
  if (e === null)
    throw new Error("Missing parent");
  return e;
}
function sn({ slot: e = P({}), name: o = "Description", props: t = {} } = {}) {
  let a = P([]);
  function n(r) {
    return a.value.push(r), () => {
      let l = a.value.indexOf(r);
      l !== -1 && a.value.splice(l, 1);
    };
  }
  return Q(we, { register: n, slot: e, name: o, props: t }), B(() => a.value.length > 0 ? a.value.join(" ") : void 0);
}
C({ name: "Description", props: { as: { type: [Object, String], default: "p" }, id: { type: String, default: () => `headlessui-description-${G()}` } }, setup(e, { attrs: o, slots: t }) {
  let a = ln();
  return U(() => se(a.register(e.id))), () => {
    let { name: n = "Description", slot: r = P({}), props: l = {} } = a, { id: p, ...m } = e, b = { ...Object.entries(l).reduce((g, [w, f]) => Object.assign(g, { [w]: E(f) }), {}), id: p };
    return H({ ourProps: b, theirProps: m, slot: r.value, attrs: o, slots: t, name: n });
  };
} });
let $e = Symbol("LabelContext");
function Te() {
  let e = A($e, null);
  if (e === null) {
    let o = new Error("You used a <Label /> component, but it is not inside a parent.");
    throw Error.captureStackTrace && Error.captureStackTrace(o, Te), o;
  }
  return e;
}
function dn({ slot: e = {}, name: o = "Label", props: t = {} } = {}) {
  let a = P([]);
  function n(r) {
    return a.value.push(r), () => {
      let l = a.value.indexOf(r);
      l !== -1 && a.value.splice(l, 1);
    };
  }
  return Q($e, { register: n, slot: e, name: o, props: t }), B(() => a.value.length > 0 ? a.value.join(" ") : void 0);
}
C({ name: "Label", props: { as: { type: [Object, String], default: "label" }, passive: { type: [Boolean], default: !1 }, id: { type: String, default: () => `headlessui-label-${G()}` } }, setup(e, { slots: o, attrs: t }) {
  let a = Te();
  return U(() => se(a.register(e.id))), () => {
    let { name: n = "Label", slot: r = {}, props: l = {} } = a, { id: p, passive: m, ...b } = e, g = { ...Object.entries(l).reduce((w, [f, h]) => Object.assign(w, { [f]: E(h) }), {}), id: p };
    return m && (delete g.onClick, delete g.htmlFor, delete b.onClick), H({ ourProps: g, theirProps: b, slot: r, attrs: t, slots: o, name: n });
  };
} });
let ze = Symbol("GroupContext");
C({ name: "SwitchGroup", props: { as: { type: [Object, String], default: "template" } }, setup(e, { slots: o, attrs: t }) {
  let a = P(null), n = dn({ name: "SwitchLabel", props: { htmlFor: B(() => {
    var l;
    return (l = a.value) == null ? void 0 : l.id;
  }), onClick(l) {
    a.value && (l.currentTarget.tagName === "LABEL" && l.preventDefault(), a.value.click(), a.value.focus({ preventScroll: !0 }));
  } } }), r = sn({ name: "SwitchDescription" });
  return Q(ze, { switchRef: a, labelledby: n, describedby: r }), () => H({ theirProps: e, ourProps: {}, slot: {}, slots: o, attrs: t, name: "SwitchGroup" });
} });
let un = C({ name: "Switch", emits: { "update:modelValue": (e) => !0 }, props: { as: { type: [Object, String], default: "button" }, modelValue: { type: Boolean, default: void 0 }, defaultChecked: { type: Boolean, optional: !0 }, name: { type: String, optional: !0 }, value: { type: String, optional: !0 }, id: { type: String, default: () => `headlessui-switch-${G()}` } }, inheritAttrs: !1, setup(e, { emit: o, attrs: t, slots: a, expose: n }) {
  let r = A(ze, null), [l, p] = rn(B(() => e.modelValue), (k) => o("update:modelValue", k), B(() => e.defaultChecked));
  function m() {
    p(!l.value);
  }
  let b = P(null), g = r === null ? b : r.switchRef, w = nn(B(() => ({ as: e.as, type: t.type })), g);
  n({ el: g, $el: g });
  function f(k) {
    k.preventDefault(), m();
  }
  function h(k) {
    k.key === W.Space ? (k.preventDefault(), m()) : k.key === W.Enter && an(k.currentTarget);
  }
  function v(k) {
    k.preventDefault();
  }
  let $ = B(() => {
    var k, s;
    return (s = (k = D(g)) == null ? void 0 : k.closest) == null ? void 0 : s.call(k, "form");
  });
  return U(() => {
    re([$], () => {
      if (!$.value || e.defaultChecked === void 0)
        return;
      function k() {
        p(e.defaultChecked);
      }
      return $.value.addEventListener("reset", k), () => {
        var s;
        (s = $.value) == null || s.removeEventListener("reset", k);
      };
    }, { immediate: !0 });
  }), () => {
    let { id: k, name: s, value: c, ...z } = e, I = { checked: l.value }, Ce = { id: k, ref: g, role: "switch", type: w.value, tabIndex: 0, "aria-checked": l.value, "aria-labelledby": r?.labelledby.value, "aria-describedby": r?.describedby.value, onClick: f, onKeyup: h, onKeypress: v };
    return F(S, [s != null && l.value != null ? F(on, Xt({ features: xe.Hidden, as: "input", type: "checkbox", hidden: !0, readOnly: !0, checked: l.value, name: s, value: c })) : null, H({ ourProps: Ce, theirProps: { ...t, ...ve(z, ["modelValue", "defaultChecked"]) }, slot: I, attrs: t, slots: a, name: "Switch" })]);
  };
} });
const cn = /* @__PURE__ */ d("span", {
  "test-id": "toggle-anchor",
  class: "sr-only"
}, "Use setting", -1), En = C({
  __name: "Toggle",
  props: {
    checked: { type: Boolean, default: !1 },
    disabled: { type: Boolean, default: !1 },
    themed: { type: Boolean, default: !1 }
  },
  emits: ["toggle"],
  setup(e, { emit: o }) {
    const t = e;
    function a() {
      o("toggle", !t.checked);
    }
    return (n, r) => (i(), j(E(un), {
      "onUpdate:modelValue": a,
      "model-value": t.checked,
      class: T([
        t.checked ? t.themed ? "bg-theme-500 focus:ring-theme-500" : "bg-indigo-600 focus:ring-indigo-500" : "bg-dynamic-neutral-300 focus:ring-indigo-500",
        t.disabled ? "opacity-700" : "",
        "relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 ring-offset-white dark:ring-offset-neutral-800"
      ])
    }, {
      default: _(() => [
        cn,
        d("span", {
          "aria-hidden": "true",
          class: T([
            t.checked ? "translate-x-5" : "translate-x-0",
            "pointer-events-none inline-block h-5 w-5 rounded-full bg-dynamic-neutral-light shadow transform ring-0 transition ease-in-out duration-200"
          ])
        }, null, 2)
      ]),
      _: 1
    }, 8, ["model-value", "class"]));
  }
}), pn = /* @__PURE__ */ d("i", { class: "far fa-comments" }, null, -1), fn = { class: "flex flex-row max-w-full p-4 bg-opacity-0 cursor-pointer xs:space-x-3 xs:p-4 bg-neutral-500 dark:hover:bg-opacity-10 hover:bg-opacity-5 border-spacing-0" }, mn = { class: "post-content" }, yn = {
  "data-testid": "post-text",
  class: "xs:!mt-6 xs:!mb-4 xs:text-lg md:text-xl text-dynamic-neutral-700 dark:text-dynamic-neutral-300"
}, hn = {
  key: 1,
  class: "relative inline-block scroll-px-4 px-4 space-x-2 overflow-x-scroll h-64 whitespace-nowrap snap-x -left-4 w-[calc(100%+2rem)] align-middle hide-scrollbar"
}, gn = ["src"], bn = ["href"], kn = ["src"], vn = { class: "flex flex-col p-3 space-y-1 text-sm break-all" }, xn = { class: "text-dynamic-neutral-800 dark:text-dynamic-neutral-200" }, wn = /* @__PURE__ */ d("i", { class: "far fa-link" }, null, -1), $n = { class: "font-bold text-dynamic-neutral-800 dark:text-dynamic-neutral-200" }, Tn = { class: "hidden text-dynamic-neutral-600 dark:text-dynamic-neutral-400 xs:flex" }, zn = { class: "text-sm text-dynamic-neutral-500 dark:text-dynamic-neutral-400" }, An = C({
  __name: "PostExpanded",
  props: {
    post: {},
    user: {},
    bookmarked: { type: Boolean },
    reacted: { type: Boolean },
    photos: {},
    links: {},
    permissions: {}
  },
  emits: [
    "bookmark-tap",
    "reaction-tap",
    "reply-tap",
    "share-tap",
    "post-tap",
    "context-menu-tap",
    "pin-tap",
    "delete-tap",
    "copy-link-tap",
    "report-tap",
    "reply-to-tap",
    "user-tap"
  ],
  setup(e, { emit: o }) {
    const t = e, a = A("auth_id", null), n = A("appConfig", {}), r = P({ x: 0, y: 0 }), l = B(() => ({
      template: t.post.text.replace(/</g, "&lt;").replace(/>/g, "&gt;").replace(/{/g, "&lcub;").replace(/}/g, "&rcub;").replace(/\[/g, "&lbrack").replace(/\]/g, "&rbrack").replace(/\n/g, "<br/>").replace(/!!([^!]+)!!/g, '<span class="italic">$1</span>').replace(/__([^_]+)__/g, '<span class="underline">$1</span>').replace(/~~([^~]+)~~/g, '<span class="line-through">$1</span>').replace(/\*\*([^*]+)\*\*/g, '<span class="font-bold">$1</span>').replace(
        /``([^`]+)``/g,
        '<span class="border rounded-md py-0.5 px-1 border-theme-500 bg-theme-100 text-theme-500">$1</span>'
      ).replace(
        /(([a-zA-Z0-9]+:\/\/)?([a-zA-Z0-9_]+:[a-zA-Z0-9_]+@)?([a-zA-Z0-9.-]+\.[A-Za-z]{2,4})(:[0-9]+)?((\/.{0,14}).*)?)/g,
        '<a href="$1" clas v-on:click.stop rel="noreferrer" target="_blank">$2$4$5...</a>'
        // ''
      ).replace(
        /#([a-zA-Z0-9_]+)/g,
        `<RouterLink v-on:click.stop v-bind:to="{name: 'tag', params: { tagId: '$1' }}" class="font-semibold text-theme-600 dark:text-theme-300 tint-rate-75">#$1</RouterLink>`
      ).replace(
        /@([a-zA-Z0-9_S]+)/g,
        `<RouterLink v-on:click.stop v-bind:to="{name: 'profile', params: { username: '$1'}}" class="font-semibold rounded-lg text-theme-600 dark:text-theme-300 tint-rate-75">@$1</RouterLink>`
      ),
      name: "PostTextContent"
    })), p = P(!1), m = P(null);
    function b() {
      console.log("emitting pin Post event..."), o("pin-tap", t.post.id);
    }
    function g() {
      console.log("emitting delete Post event..."), o("delete-tap", t.post.id);
    }
    function w() {
      console.log("emitting report Post event..."), o("report-tap", t.post.id);
    }
    function f() {
      console.log("emitting copy link event..."), o("copy-link-tap", t.post.id), navigator.clipboard && navigator.clipboard.writeText(
        window.location.origin + "/post/" + t.post.id
      );
    }
    function h(s) {
      o("reply-to-tap", s.id);
    }
    async function v() {
      o("share-tap", t.post.id);
      const s = {
        title: `Post by ${t.user?.name}`,
        text: t.post.text,
        url: window.location.origin + "/post/" + t.post.id
      };
      try {
        await window.navigator.share(s);
      } catch (c) {
        console.log(c);
      }
    }
    function $() {
      p.value = !p.value;
    }
    fe(m.value, () => console.log("CLikc outside"));
    const k = B(() => ({
      lists: [
        {
          items: [
            {
              label: "Pin",
              icon: "fas fa-thumbtack",
              action: b,
              show: a === t.post.user_id
            },
            {
              label: "Link",
              icon: "fas fa-link",
              action: f,
              show: !0
            },
            {
              label: "Share via...",
              icon: "fas fa-share-alt",
              action: v,
              show: "share" in navigator
            },
            {
              label: "Delete",
              icon: "fas fa-trash",
              action: g,
              show: a === t.post.user_id
            },
            {
              label: "Report",
              icon: "fas fa-flag",
              action: w,
              show: !0
            }
          ]
        },
        {
          items: [
            {
              label: "Cancel",
              icon: "fas fa-times",
              show: !0,
              action: () => console.log(`cancel link ${t.post.id}`)
            }
          ]
        }
      ]
    }));
    return (s, c) => (i(), u(S, null, [
      s.post.reply_to_id !== null && s.post.reply_to ? (i(), u("a", {
        key: 0,
        onClick: c[0] || (c[0] = O((z) => h(s.post.reply_to), ["stop"])),
        class: "relative flex items-center px-6 py-4 space-x-1 text-xs font-medium leading-relaxed border-b cursor-pointer text-dynamic-neutral-400 hover:text-dynamic-neutral-500 dark:text-dynamic-neutral-400 dark:hover:text-dynamic-neutral-300 border-dynamic-neutral-100 dark:border-dynamic-neutral-800"
      }, [
        pn,
        d("span", null, "Replying to @" + x(s.post.reply_to.user?.username), 1)
      ])) : y("", !0),
      d("a", {
        onClick: c[9] || (c[9] = O((z) => o("post-tap"), ["stop"])),
        class: "relative post-element"
      }, [
        (i(), j(le, { to: "body" }, [
          p.value ? (i(), j(he, {
            key: 0,
            "horizontal-position": r.value.x,
            "vertical-position": r.value.y,
            config: k.value,
            onCloseContextTap: c[1] || (c[1] = (z) => p.value = !1),
            ref_key: "contextElementRef",
            ref: m
          }, null, 8, ["horizontal-position", "vertical-position", "config"])) : y("", !0)
        ])),
        d("div", fn, [
          d("div", mn, [
            s.post && s.user ? (i(), j(ye, {
              key: 0,
              user: s.user,
              "readable-date": s.post.created_at_readable ?? "",
              network: s.post.network || void 0,
              bookmarked: !!s.bookmarked,
              withProfile: !0,
              onBookmarkTap: c[2] || (c[2] = () => o("bookmark-tap", s.post.id)),
              onContextMenuTap: c[3] || (c[3] = (z) => {
                r.value.x = z.left, r.value.y = z.top, $();
              }),
              onUserTap: c[4] || (c[4] = () => o("user-tap"))
            }, null, 8, ["user", "readable-date", "network", "bookmarked"])) : y("", !0),
            d("p", yn, [
              (i(), j(M(l.value)))
            ]),
            s.post.photos?.length ? (i(), u("div", hn, [
              (i(!0), u(S, null, R(s.post.photos || [], (z, I) => (i(), u("div", {
                key: I,
                class: "box-border relative inline-block w-auto h-full overflow-hidden align-middle rounded-lg bg-dynamic-neutral-500/20 snap-start"
              }, [
                d("img", {
                  class: "inline-flex object-cover object-center w-auto h-full max-h-full",
                  src: z ? E(n).content_url + "posts/large/" + z.filename : "",
                  alt: ""
                }, null, 8, gn)
              ]))), 128))
            ])) : y("", !0),
            s.links && s.links.length ? (i(), u("a", {
              key: 2,
              href: s.links[0].url,
              target: "_blank",
              rel: "noreferrer",
              onClick: c[5] || (c[5] = O(() => {
              }, ["stop"])),
              class: "flex flex-col overflow-hidden bg-opacity-0 border rounded-md sm:flex-row md:flex-col lg:flex-row border-dynamic-neutral-200 dark:border-dynamic-neutral-700 bg-nuetral-500 hover:bg-opacity-10"
            }, [
              s.links[0].image_filename ? (i(), u("img", {
                key: 0,
                src: E(n).content_url && s.links[0].image_filename ? E(n).content_url + "links/hd/" + s.links[0].image_filename : "",
                class: "flex-shrink-0 object-cover sm:w-48 md:w-[unset] lg:w-48",
                loading: "lazy"
              }, null, 8, kn)) : y("", !0),
              d("div", vn, [
                d("p", xn, [
                  wn,
                  Ee("  "),
                  d("span", null, x(s.links[0].url.replace(
                    /(([a-zA-Z0-9]+:\/\/)?([a-zA-Z0-9_]+:[a-zA-Z0-9_]+@)?([a-zA-Z0-9.-]+\.[A-Za-z]{2,4})(:[0-9]+)?((\/.{0,14}).*)?)/g,
                    "$4"
                  )), 1)
                ]),
                d("p", $n, x(s.links[0].title), 1),
                d("p", Tn, x(s.links[0].description), 1)
              ])
            ], 8, bn)) : y("", !0),
            d("div", zn, " Posted " + x(s.post.created_at_readable), 1),
            q(ie, {
              reacted: !!s.reacted,
              bookmarked: !!s.bookmarked,
              onBookmarkTap: c[6] || (c[6] = () => o("bookmark-tap", s.post.id)),
              onReactionTap: c[7] || (c[7] = () => o("reaction-tap", s.post.id)),
              onReplyTap: c[8] || (c[8] = () => o("reply-tap", s.post.id)),
              onShareTap: v,
              post: s.post
            }, null, 8, ["reacted", "bookmarked", "post"])
          ])
        ])
      ])
    ], 64));
  }
});
export {
  Pn as Button,
  On as ButtonGroup,
  _e as Card,
  Bn as CenteredContainer,
  jn as Input,
  ie as PostActionSection,
  An as PostExpanded,
  Sn as PostFeedItem,
  ye as PostHeader,
  me as ProfilePhoto,
  En as Toggle
};
