import { getToken, type Messaging } from "firebase/messaging";

export default async function getFirebaseToken(messaging: Messaging) {
  // Get registration token. Initially this makes a network call, once retrieved
  // subsequent calls to getToken will return from cache.

  const configuration = import.meta.env.PROD
    ? {
        vapidKey: import.meta.env.VITE_FIREBASE_MESSAGING_VAPID,
        serviceWorkerRegistration: await window.navigator.serviceWorker.ready,
      }
    : {
        vapidKey: import.meta.env.VITE_FIREBASE_MESSAGING_VAPID,
      };

  return await getToken(messaging, configuration);
  // .then((currentToken) => {
  //   if (currentToken) {
  //     // Send the token to your server and update the UI if necessary
  //     // ...
  //   } else {
  //     // Show permission request UI
  //     console.log(
  //       "No registration token available. Request permission to generate one."
  //     );
  //     // ...
  //   }
  // })
  // .catch((err) => {
  //   console.log("An error occurred while retrieving token. ", err);
  //   // ...
  // });
}
