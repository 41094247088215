import { useLocalStorage } from "@vueuse/core";
import { createRouter, createWebHistory } from "vue-router";

import useAuthStore from "@/stores/common/auth";

import { ROUTES } from ".";

import routes from "@/router/routes";

const router = createRouter({
  history: createWebHistory(),
  scrollBehavior: (to, from) => {
    if (to.hash) {
      return {
        el: to.hash,
        behavior: "smooth",
      };
    }
    if (from.name === to.name) {
      console.log({
        from,
        to,
      });
      return {
        el: "#main",
        behavior: "smooth",
      };
    }
    return {
      top: 0,
    };
  },
  routes,
});

router.beforeEach((to, _from, next) => {
  const authStore = useAuthStore();
  const onboardingComplete = useLocalStorage("onboarding-complete", false);

  if (onboardingComplete.value && to.name === ROUTES.ONBOARDING)
    return next({ name: ROUTES.HOME });
  if (!onboardingComplete.value && to.name !== ROUTES.ONBOARDING)
    return next({ name: ROUTES.ONBOARDING });
  if (to.matched.some((r) => r.meta.secure) && !authStore.isAuthenticated) {
    // window.location.replace(
    //   // "http://" + window.location.hostname + ":8000/bypass"
    //   import.meta.env.VITE_BASE_URL + "/acceso"
    // );
    return next({ name: ROUTES.LOGIN });
  }
  if (to.name === ROUTES.LOGIN && authStore.isAuthenticated) {
    return next({ name: ROUTES.HOME });
  }

  return next();
});

export { default as ROUTES } from "@/router/constants";

export default router;
