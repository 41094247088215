<script lang="ts" setup>
import { computed, onMounted, onUpdated, ref } from "vue";
import { onBeforeRouteUpdate, useRoute } from "vue-router";

import ChatUserProfile from "@/components/ChatUserProfile.vue";
import MessageThreadWindow from "@/components/MessageThreadWindow.vue";

import useMessagesPageStore from "@/stores/activities/messages-page-store";
import useAuthStore from "@/stores/common/auth";
import useMessagesStore from "@/stores/resources/messages-store";
import useChatStore from "@/stores/resources/new-chat-store";
import useUsersStore from "@/stores/resources/users-store";

import { ROUTES } from "@/router";
import type { MessagePayloadType } from "@/types/appTypes";
import type { DirectMessageThread } from "@/types/routeTypes";

const route = useRoute() as DirectMessageThread;
const messageStore = useMessagesStore();
const authStore = useAuthStore();
const userStore = useUsersStore();
const chatStore = useChatStore();

const messagePageStore = useMessagesPageStore();

const username = computed(() => route.params.username);

const chatId = computed(
  () => chatStore.usernameChatIdMap.get(username.value.toLowerCase()) as number
);

const user = computed(
  () =>
    userStore.usersById[
      userStore.userIdByUsernameMap[route.params.username.toLowerCase()]
    ]
);

const messages = computed(() => {
  const filteredMessages = Array.from(
    chatStore.messageIdsByChatId.get(chatId.value) || []
  )
    .map((id) => messageStore.messagesById.get(id) as Message)
    .filter((m) => m !== undefined)
    .sort((a, b) =>
      (a?.created_at as string) > (b.created_at as string) ? 1 : -1
    )
    .map((m) => {
      return {
        ...m,
        from_user: userStore.usersById[m.from_user_id] ?? {
          acronym: "Unknown",
          name: "unknown",
          username: "unknown",
          id: 0,
        },
      };
    });

  console.log(`Messages for @${username.value}`, filteredMessages);
  return filteredMessages;
});

const chatWindowRef = ref();

const chatScrollElement = computed(() =>
  user.value ? chatWindowRef.value?.messageListScrollContainer : {}
);

async function handleMessagePublish(
  payload: MessagePayloadType,
  onComplete: () => void
) {
  if (chatId.value)
    await messagePageStore.sendMessage(
      chatId.value,
      payload.text,
      payload.photos
    );
  else if (user.value)
    await messagePageStore.sendMessageToNewChat(
      [user.value.id],
      payload.text,
      payload.photos
    );
  jumpToLatestMessage();
  onComplete();
}

async function jumpToLatestMessage() {
  if (!user.value) return;
  if (chatScrollElement.value)
    chatScrollElement.value.scrollTo(0, chatScrollElement.value.scrollHeight);
  document.documentElement.scrollTo(0, document.documentElement.scrollHeight);
}

onMounted(async () => {
  // if (route.name === ROUTES.MESSAGE_THREAD && route.params.username) {
  //   await userStore.fetchUserByUsername(route.params.username as string);
  //   await messagePageStore.loadDirectChat(route.params.username);
  //   jumpToLatestMessage();
  // }
});

onUpdated(() => {
  if (messages.value.length) {
    jumpToLatestMessage();
  }
});

onBeforeRouteUpdate(async (to, from, next) => {
  // if (
  //   [to.name, from.name].includes(ROUTES.MESSAGE_THREAD) &&
  //   to.params.username !== from.params.username
  // ) {
  //   const nextChatId = chatStore.usernameChatIdMap.get(
  //     to.params.username as string
  //   );
  //   if (chatStore.messageIdsByChatId.get(nextChatId as number)?.size) {
  //     next();
  //     userStore.fetchUserByUsername(to.params.username as string);
  //     messagePageStore.loadDirectChat(to.params.username as string);
  //     jumpToLatestMessage();
  //   } else {
  //     await userStore.fetchUserByUsername(to.params.username as string);
  //     await messagePageStore.loadDirectChat(to.params.username as string);
  //     jumpToLatestMessage();
  //     next();
  //   }
  // }
});
</script>

<template>
  <MessageThreadWindow
    v-if="user"
    :title="user.name"
    :subtitle="`@${user.username}`"
    :photo-icon-text="user.acronym"
    :messages="messages"
    :current-user="user"
    :is-loading-user="!user && messagePageStore.loading"
    :is-loading-messages="messagePageStore.loadingChat && messages.length > 1"
    @message="handleMessagePublish"
    :show-user-icon="false"
    ref="chatWindowRef"
  >
    <template #top>
      <ChatUserProfile v-if="user" :user="user" />
    </template>
  </MessageThreadWindow>
</template>
