import { POSTS_ENDPOINT } from "./constants";

import api from "@/services/api";

export async function getPostTimeline() {
  return api.get<Array<Post>>(`${POSTS_ENDPOINT}/`);
}

export async function publishPost(
  networkSlug: string | undefined,
  text: string,
  replyPostId?: number,
  photos?: Array<Record<string, string>>
) {
  return api.post<{ post: Post; reply?: Post }>(`${POSTS_ENDPOINT}/`, {
    text,
    reply_to_id: replyPostId,
    network_slug: networkSlug,
    vapor_postable_photos: photos,
  });
}

export async function publishThread(
  networkSlug: string | undefined,
  posts: {
    text: string;
    photos?: Array<Record<string, string>>;
  }[],
  replyPostId?: number
) {
  return api.post<{ network: Network; post: Post; replies: Array<Post> }>(
    `${POSTS_ENDPOINT}/thread/`,
    {
      networkSlug,
      reply_to_id: replyPostId,
      posts,
    }
  );
}

export async function deletePost(postId: number) {
  return api.delete<{ user: User }>(`${POSTS_ENDPOINT}/${postId}/`);
}
