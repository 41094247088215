import { acceptHMRUpdate, defineStore } from "pinia";
import { computed, ref } from "vue";

export type ContextMenuItem = {
  label: string;
  icon: string;
  show?: boolean;
  action: () => void;
};

export type ContextMenuConfig = {
  lists: Array<{
    label?: string;
    description?: string;
    direction?: "vertical" | "horizontal";
    items: Array<ContextMenuItem>;
  }>;
  top?: number;
  left?: number;
};

const useOverlayManager = defineStore("overlay-manager-store", function () {
  const isUserBottomSheetOpen = ref(false);

  const isOverlayOpen = computed(() => !!isUserBottomSheetOpen.value);

  function reset(callback?: <T>(...args: Array<T>) => void) {
    if (typeof callback === "function") callback();
    isUserBottomSheetOpen.value = false;
  }

  // #region Post overlays.

  function showPostComposeModal() {
    reset();
  }

  function showPostContextMenu() {
    reset();
  }

  function showPostDeleteAlertModal(
    postId: number,
    callback?: <T>(...args: Array<T>) => void
  ) {
    if (typeof callback === "function") callback();
  }

  // #endregion

  function showRecentPeopleModal() {
    reset();
  }

  function showUserBottomSheet() {
    reset();
    isUserBottomSheetOpen.value = true;
  }

  return {
    reset,
    showPostComposeModal,
    showPostContextMenu,
    showRecentPeopleModal,
    showPostDeleteAlertModal,
    showUserBottomSheet,

    isOverlayOpen,
    isUserBottomSheetOpen,
  };
});

export default useOverlayManager;

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useOverlayManager, import.meta.hot));
}
