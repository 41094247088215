import ROUTES, { NETWORK_ROUTE } from "@/router/constants";

const NetworkApp = () => import("@/apps/NetworkApp.vue");
const NetworkPostFeed = () => import("@/partials/NetworkPostFeed.vue");

export default {
  name: NETWORK_ROUTE,
  path: "/axis/:slug",
  component: NetworkApp,
  meta: { secure: true },
  children: [
    {
      name: ROUTES.NETWORK_FEED,
      path: "",
      component: NetworkPostFeed,
    },
  ],
  redirect: () => ({
    name: ROUTES.NETWORK_FEED,
  }),
};
