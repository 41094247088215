<script setup lang="ts">
import { ref } from "vue";

import TipTapEditor from "@/components/TipTapEditor.vue";

import useVaporUpload from "@/hooks/useVaporUpload";

import type { PostPayloadType } from "@/types/appTypes";

const props = withDefaults(defineProps<PropTypes>(), {
  useLargeTextarea: false,
});

const emit = defineEmits<EmitTypes>();

const text = ref("");
const isPublishing = ref(false);
const photos = ref<Array<File>>([]);
const photoUrls = ref<Array<string>>([]);

const { upload } = useVaporUpload();

async function handlePublishPost() {
  isPublishing.value = true;
  const localText = text.value;
  const s3PhotoObjects = await Promise.all(
    photos.value.map(async (photo) => {
      const response = await upload(photo);
      return {
        uuid: response.uuid,
        key: response.key,
        content_type: photo.name,
      };
    })
  );

  emit("publish", { text: localText, photos: s3PhotoObjects }, () => {
    text.value = "";
    isPublishing.value = false;
    photoUrls.value = [];
    photos.value = [];
  });
}

function handleInput(event: Event) {
  const element = event.target as HTMLInputElement;
  const files = element.files as FileList;

  const localPhotoUrls: Array<string> = [];
  const localPhotos: Array<File> = [];

  Array.from(files).forEach((file) => {
    localPhotoUrls.push(URL.createObjectURL(file));
    localPhotos.push(file);
  });

  photoUrls.value.push(...localPhotoUrls);
  photos.value.push(...localPhotos);
}

function handlePhotoRemoval(index: number) {
  console.log({
    index,
    photoUrls,
    photos,
  });

  const localPhotos = photos.value;
  const localPhotoUrls = photoUrls.value;

  localPhotos.splice(index, 1);
  localPhotoUrls.splice(index, 1);

  photoUrls.value = localPhotoUrls;
  photos.value = localPhotos;
}

function handleTextUpdate(textContent: string) {
  text.value = textContent;
}

type PropTypes = {
  useLargeTextarea?: boolean;
};

type EmitTypes = {
  (e: "publish", payload: PostPayloadType, onComplete: () => void): void;
};
</script>

<template>
  <TipTapEditor
    button-text="Publish Post"
    show-user-avatar
    show-percentage-indicator
    aux-as-file-input
    allow-multiple-files
    id="post-compose-editor"
    :model-value="text"
    :allowed-file-types="['image/jpeg', 'image/png', 'image/webp', 'image/jpg']"
    @send="handlePublishPost"
    @aux-press="handleInput"
    @update:model-value="handleTextUpdate"
    :use-large-textarea="props.useLargeTextarea"
    :is-loading="isPublishing"
    loading-text="Publishing..."
  >
    <div
      v-if="photoUrls.length > 0"
      class="inline-block w-full max-w-[calc(100vw-5rem)] p-2 my-2 space-x-2 overflow-x-scroll sm:max-w-screen-xs hide-scrollbar whitespace-nowrap"
    >
      <div
        class="relative inline-block w-10 h-10 sm:w-20 sm:h-20"
        v-for="(photo, key) in photoUrls"
        :key="key"
      >
        <button
          @click="() => handlePhotoRemoval(key)"
          class="absolute flex flex-col items-center justify-center w-4 h-4 text-lg leading-none border-2 rounded-full bg-dynamic-neutral-light border-dynamic-neutral-100 dark:border-dynamic-neutral-800 -right-1 -top-1"
        >
          <i
            class="text-lg text-red-300 fas fa-circle-minus hover:text-red-700 dark:hover:text-red-300"
          ></i>
        </button>
        <img
          class="object-cover object-center w-full h-full rounded-xl"
          :src="photo"
        />
      </div>
    </div>
  </TipTapEditor>
</template>
