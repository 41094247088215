import { POST_DETAIL_ROUTE } from "@/router/constants";

const PostDetailApp = () => import("@/apps/PostDetailApp.vue");

export default {
  name: POST_DETAIL_ROUTE,
  path: "/post/:postId",
  component: PostDetailApp,
  meta: { secure: true },
};
