import { acceptHMRUpdate, defineStore } from "pinia";
import { computed } from "vue";
import { useRoute } from "vue-router";

import useBookmarkStore from "@/stores/resources/bookmarks-store";
import useNotificationStore from "@/stores/resources/notifications-store";
import usePostStore from "@/stores/resources/posts-store";
import useReactionStore from "@/stores/resources/reactions-store";
import useUserStore from "@/stores/resources/users-store";

import usePageState from "@/hooks/usePageState";
import usePostActions from "@/hooks/usePostActions";
import useUiNotifications from "@/hooks/useUiNotifications";

import useFollowStore from "../resources/follow-store";

import { loadProfilePageData } from "@/services/page-service";
import type { PostPayloadType } from "@/types/appTypes";
import callOptional from "@/utils/callOptional";
// import { deletePost } from "@/services/posts-service";

const useProfilePageStore = defineStore("profilePage", () => {
  const { loading, errorWhileLoading, fetchPageData } = usePageState();
  const {
    createPost,
    createPostThread,
    removePost,
    toggleBookmark,
    toggleReaction,
  } = usePostActions();

  const { notify } = useUiNotifications();

  const postStore = usePostStore();
  const userStore = useUserStore();
  const bookmarkStore = useBookmarkStore();
  const reactionStore = useReactionStore();
  const notificationStore = useNotificationStore();
  const followStore = useFollowStore();

  const route = useRoute();
  // const username = (route.params.username as string).toLowerCase();

  const profilePosts = computed(() => {
    const username = (route.params.username as string).toLowerCase();
    return Array.from(postStore.postIdsByUsername[username] || new Set())
      .map((id) => postStore.postsById[id as number])
      .map((post) => {
        const user = userStore.usersById[post.user_id];
        if (!user) return null;
        return {
          ...post,
          user,
          links: Array.from(postStore.linkIdsByPostId.get(post.id) || [])
            .map((id) => postStore.linksById.get(id))
            .filter((link) => link !== undefined) as Link[],
          isBookmarked: !!bookmarkStore.postBookmarksById.get(post.id),
          hasReacted: !!reactionStore.userRectionsByPostId.has(post.id),
        };
      })
      .filter((post) => post !== null);
  });

  async function loadProfileData(username: string) {
    await fetchPageData({
      async fetch() {
        await new Promise((r) => setTimeout(r, 1000));

        const { data } = await loadProfilePageData(username);

        const {
          user,
          posts,
          reactions,
          bookmarks,
          currentUserFollowsUser,
          userFollowsCurrentUser,
        } = data;

        notificationStore.parseNotificatiosFromRequestData(data);

        if (currentUserFollowsUser) followStore.parseFollowings([user]);
        if (userFollowsCurrentUser) followStore.parseFollowers([user]);

        bookmarkStore.parseBookmarks(bookmarks);
        reactionStore.parseReactions(reactions);
        postStore.parsePosts(posts);
        userStore.parseUsers([user]);
      },
      error() {
        console.log("Failed to load profile data.");
      },
    });
  }

  function publishPostToProfileFeed(
    { text, photos }: PostPayloadType,
    onComplete?: (...args: unknown[]) => void
  ) {
    createPost({
      text,
      photos,
      onSuccess(post) {
        callOptional(onComplete, post);
        notify({
          title: "Post submitted successfully",
          type: "success",
        });
      },

      onError() {
        notify({
          title: "Post submission error",
          text: "Something went wrong while submitting your post.",
          type: "success",
        });
      },
    });
  }

  function publishPostThreadToProfile(
    {
      posts,
    }: {
      posts: Array<{ text: string; photos?: Array<Record<string, string>> }>;
    },
    onComplete?: (...args: unknown[]) => void
  ) {
    createPostThread({
      posts,
      onSuccess(result) {
        callOptional(onComplete, result);
      },
    });
  }

  function removePostFromProfileFeed(
    postId: number,
    onComplete?: (...args: unknown[]) => void
  ) {
    removePost({
      postId,
      onSuccess() {
        callOptional(onComplete);
      },

      onError() {
        notify({
          title: "Post removal error",
          text: "Something went wrong while removing post.",
          type: "error",
        });
      },
    });
  }

  return {
    loadProfileData,
    loading,
    errorWhileLoading,

    posts: profilePosts,
    createPost: publishPostToProfileFeed,
    createThread: publishPostThreadToProfile,
    removePost: removePostFromProfileFeed,
    toggleBookmark,
    toggleReaction,
  };
});

export default useProfilePageStore;

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useProfilePageStore, import.meta.hot));
}
