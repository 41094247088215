import { acceptHMRUpdate, defineStore } from "pinia";
import { ref } from "vue";

import type { InitialRequestData } from "@/services/app-service";

const useNetworkStore = defineStore("networks", function () {
  const networksBySlug = ref<Map<string, Network>>(new Map());

  function init(data: InitialRequestData) {
    setNetworks(data.resources.networks);
  }

  function setNetworks(networks: Array<Network>) {
    const networksMap = new Map();

    networks.forEach((n) => networksMap.set(n.slug, n));

    networksBySlug.value = new Map([...networksBySlug.value, ...networksMap]);

    console.log({
      networksMap,
      networksBySlug,
      networks,
    });
  }

  function getBySlug(slug: string) {
    // fetch network by slug.
  }

  function parseNetworks(networks: Array<Network>) {
    // Parse other data...
    setNetworks(networks);
  }

  return {
    getBySlug,
    networksBySlug,
    parseNetworks,
    init,
  };
});

export default useNetworkStore;

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useNetworkStore, import.meta.hot));
}
