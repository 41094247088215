import { defineStore, acceptHMRUpdate } from "pinia";
import { computed, ref } from "vue";

import useNotificationStore from "@/stores/resources/notifications-store";
import useUsersStore from "@/stores/resources/users-store";

import router, { ROUTES } from "@/router";
import { login, logout, me, authCheck } from "@/services/auth-service";

const useAuthStore = defineStore("auth", () => {
  const userStore = useUsersStore();
  const notificationStore = useNotificationStore();

  const id = ref<undefined | number>(undefined);
  const user = computed(() => userStore.usersById[id.value ?? -1]);
  const isAuthenticated = computed(() => user.value !== undefined);

  async function sessionStart(
    username: string,
    password: string,
    remember = false
  ) {
    try {
      const { data } = await login(username, password, remember);
      userStore.parseUsers([data]);
      id.value = data.id;
      router.push({ name: ROUTES.HOME });
    } catch {
      return false;
    }
  }

  async function loadSessionState() {
    if (!(await sessionCheck())) return null;
    const { data } = await me();
    const loggedInUser = data ?? undefined;
    userStore.parseUsers([loggedInUser]);
    id.value = loggedInUser.id;
    return loggedInUser;
  }

  async function sessionCheck() {
    const {
      data: { authenticated },
    } = await authCheck();
    return authenticated;
  }

  async function sessionEnd() {
    await logout();
    userStore.$reset();
    notificationStore.stopPushNotifications();
    notificationStore.stopWebSockets();
    router
      .push({
        name: ROUTES.LOGIN,
      })
      .then(() => {
        id.value = undefined;
      });
  }

  return {
    id,
    user,
    isAuthenticated,
    sessionStart,
    sessionEnd,
    sessionCheck,
    loadSessionState,
  };
});

export default useAuthStore;

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useAuthStore, import.meta.hot));
}
