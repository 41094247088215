import { acceptHMRUpdate, defineStore } from "pinia";
import { ref } from "vue";

import useExplorePageStore from "@/stores/activities/explore-page-store";
import useHomePageStore from "@/stores/activities/home-page-store";
import useUserSuggestionStore from "@/stores/common/user-suggestion-store";
import useBookmarkStore from "@/stores/resources/bookmarks-store";
import useFollowStore from "@/stores/resources/follow-store";
import useMessagesStore from "@/stores/resources/messages-store";
import useNetworkStore from "@/stores/resources/networks-store";
import useChatStore from "@/stores/resources/new-chat-store";
import useNotificationStore from "@/stores/resources/notifications-store";
import usePostStore from "@/stores/resources/posts-store";
import useReactionsStore from "@/stores/resources/reactions-store";
import useUserStore from "@/stores/resources/users-store";

import {
  loadAppInitialState,
  type InitialRequestData,
} from "@/services/app-service";

const useAppStore = defineStore("app-store", function () {
  const hasLoadedInitialState = ref(false);

  const explorePageStore = useExplorePageStore();
  const homePageStore = useHomePageStore();

  const bookmarkStore = useBookmarkStore();
  const chatStore = useChatStore();
  const followStore = useFollowStore();
  const messageStore = useMessagesStore();
  const networkStore = useNetworkStore();
  const notificationStore = useNotificationStore();
  const postStore = usePostStore();
  const reactionStore = useReactionsStore();
  const userStore = useUserStore();

  const userSuggestionStore = useUserSuggestionStore();

  async function parseInitialState(data: InitialRequestData) {
    const {
      users,
      reactions,
      posts,
      notifications,
      networks,
      messages,
      follows,
      followers,
      chats,
      bookmarks,
      suggestions,
    } = data.resources;

    await new Promise((resolve) => {
      bookmarkStore.parseBookmarks(bookmarks);
      chatStore.parseChats(chats);
      Object.entries(
        messages.reduce(function (acc, curr) {
          const nextAcc = acc;
          if (curr.chat_id) {
            nextAcc[curr.chat_id] ||= [];
            nextAcc[curr.chat_id].push(curr.id);
          }
          return nextAcc;
        }, {} as Record<number, Array<number>>)
      ).forEach(([chatId, messages]) => {
        chatStore.setMessageIdsbyChatId(parseInt(chatId), messages);
      });
      followStore.parseFollowings(follows);
      followStore.parseFollowers(followers);
      messageStore.parseMessages(messages);
      networkStore.parseNetworks(networks);
      notificationStore.parseNotificatiosFromRequestData({ notifications });
      postStore.parsePosts(posts);
      reactionStore.parseReactions(reactions);
      userStore.parseUsers(users);

      homePageStore.init(data);
      explorePageStore.init(data);

      userSuggestionStore.parseUserSuggestions(suggestions);

      resolve(undefined);
    });
  }

  async function performInitialFetch() {
    const { data, status } = await loadAppInitialState();
    if (status !== 200) return;
    console.log({
      initialState: data,
    });
    await parseInitialState(data);
    hasLoadedInitialState.value = true;
  }

  return {
    hasLoadedInitialState,
    performInitialFetch,
  };
});

export default useAppStore;

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useAppStore, import.meta.hot));
}
