<script lang="ts" setup>
import MobileLandscapeNav from "@/components/MobileLandscapeNav.vue";
import MobilePortraitNav from "@/components/MobilePortraitNav.vue";

import { ROUTES } from "@/router";

const tabs = [
  { icon: "fa-home", route: { name: ROUTES.HOME } },
  { icon: "fa-search", route: { name: ROUTES.EXPLORE } },
  { icon: "fa-at", route: { name: ROUTES.NOTIFICATIONS } },
  { icon: "fa-envelope", route: { name: ROUTES.MESSAGES } },
];
</script>

<template>
  <MobileLandscapeNav :tabs="tabs" />
  <MobilePortraitNav :tabs="tabs" />
</template>
