<script lang="ts" setup>
import useAuthStore from "@/stores/common/auth";
import useOverlayManager from "@/stores/common/overlay-manager";

import UserBottomSheet from "./UserBottomSheet.vue";

const authStore = useAuthStore();
const overlayManagerStore = useOverlayManager();
</script>
<template>
  <slot></slot>

  <Transition
    enter-active-class="duration-300 ease-in-out"
    enter-from-class="opacity-0"
    enter-to-class="opacity-100"
    leave-active-class="duration-300 ease-out"
    leave-from-class="opacity-100"
    leave-to-class="opacity-0"
  >
    <div
      v-show="overlayManagerStore.isOverlayOpen"
      @click="() => overlayManagerStore.reset()"
      class="fixed inset-0 z-50 bg-dynamic-dark/20 glass-blur md:hidden"
      id="overlay-backdrop"
    ></div>
  </Transition>
  <UserBottomSheet
    id="user-bottom-sheet"
    class="fixed z-50 md:hidden transform-gpu"
    @logout-tap="authStore.sessionEnd"
    @close="overlayManagerStore.reset"
    :me="authStore.user"
    :show="!!(overlayManagerStore.isUserBottomSheetOpen && authStore.user)"
  />
</template>
@/stores/common/auth @/stores/common/overlay-manager
