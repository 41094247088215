<script setup lang="ts">
import { Card, ProfilePhoto } from "@allaxis/ui";

import useAsset from "@/hooks/useAsset";

import SideMenuItem from "./SideMenuItem.vue";

import { ROUTES } from "@/router";

defineProps<PropTypes>();
const emit = defineEmits<EmitTypes>();

const asset = useAsset();

function handleClosetap() {
  emit("close");
}

type PropTypes = {
  me: User;
  show: boolean;
};

type EmitTypes = {
  (e: "settingsTap"): void;
  (e: "logoutTap"): void;
  (e: "close"): void;
};
</script>

<template>
  <div
    class="fixed inset-0 flex flex-col items-center justify-end pointer-events-none"
  >
    <Transition
      enter-active-class="duration-300 ease-in-out"
      enter-from-class="translate-y-[28rem] transform-gpu opacity-25"
      enter-to-class="translate-y-0 opacity-100 transform-gpu"
      leave-active-class="duration-300 ease-out"
      leave-from-class="translate-y-0 transform-gpu"
      leave-to-class="translate-y-[28rem]"
    >
      <Card
        :padded="false"
        class="flex w-full shadow-lg pointer-events-auto !shadow-black/50 !shadow-2xl transition-transform mb-0 !rounded-b-none !rounded-t-2xl ui-item p-0 pb-0-safe max-w-screen-sm !bottom-0"
        @click.stop="handleClosetap"
        v-show="show"
      >
        <div class="flex flex-row items-center p-4 space-x-4">
          <ProfilePhoto
            :placeholder-text="me.acronym"
            size="sm"
            :image-url="
              asset(
                'profile/small/' + me.profile_photo?.filename,
                !!me.profile_photo
              )
            "
          />
          <div
            class="flex flex-col flex-grow truncate transition-all duration-300 ease-out overflow-x-clip 2xl:ml-0 2xl:opacity-100"
          >
            <p
              class="font-medium text-md text-dynamic-neutral-800 dark:text-dynamic-neutral-200"
            >
              {{ me.name }}
            </p>
            <p
              class="text-xs font-medium text-dynamic-neutral-700 dark:text-dynamic-neutral-300"
            >
              @{{ me.username }}
            </p>
          </div>
        </div>
        <section
          class="flex flex-col px-2 pb-4 space-y-2 text-dynamic-neutral-700 dark:text-dynamic-neutral-200"
        >
          <SideMenuItem
            :expanded="true"
            :item="{
              label: 'Profile',
              icon: 'far fa-user !text-center w-8',
              activeIcon: 'fas fa-user',
              route: {
                name: ROUTES.PROFILE,
                params: {
                  username: me.username,
                },
              },
            }"
          />

          <SideMenuItem
            :expanded="true"
            :item="{
              label: 'Bookmarks',
              icon: 'far fa-bookmark !text-center w-8',
              activeIcon: 'fas fa-bookmark',
              route: {
                name: ROUTES.BOOKMARKS,
              },
            }"
          />

          <SideMenuItem
            :expanded="true"
            :item="{
              label: 'People',
              icon: 'far fa-user-group !text-center w-8',
              activeIcon: 'fas fa-user-group',
              route: {
                name: ROUTES.PEOPLE,
              },
            }"
          />

          <SideMenuItem
            :expanded="true"
            :item="{
              label: 'Preferences',
              icon: 'far fa-sliders-up !text-center w-8',
              activeIcon: 'fas fa-sliders-up',
              route: {
                name: ROUTES.SETTINGS,
              },
            }"
          />

          <SideMenuItem
            :expanded="true"
            :item="{
              label: 'Logout',
              icon: 'far fa-power-off !text-center w-8 text-red-500',
              activeIcon: 'fas fa-power-off',
              tapHandler: () => {
                emit('logoutTap');
              },
            }"
          />
        </section>
      </Card>
    </Transition>
  </div>
</template>
