<script setup lang="ts">
import { Card, CenteredContainer } from "@allaxis/ui";
import { onMounted } from "vue";
import { onBeforeRouteLeave } from "vue-router";

import ContentSideBar from "@/components/ContentSideBar.vue";
import EmptyPlaceholder from "@/components/EmptyPlaceholder.vue";
import LoadingNotificationListItem from "@/components/LoadingNotificationListItem.vue";
import NotificationMention from "@/components/NotificationMention.vue";
import PageContainer from "@/components/PageContainer.vue";

import useNotificationPageStore from "@/stores/activities/notification-page-store";
import usePostStore from "@/stores/resources/posts-store";
import useUsersStore from "@/stores/resources/users-store";

const notificationPageStore = useNotificationPageStore();
const userStore = useUsersStore();
const postStore = usePostStore();

onMounted(async function () {
  // await notificationPageStore.loadPageData();
});

onBeforeRouteLeave(() => {
  notificationPageStore.clearCurrentNotifications();
});
</script>

<template>
  <PageContainer title="Notifications">
    <CenteredContainer>
      <div class="w-full max-w-5xl space-y-4 bg-opacity-25">
        <section class="max-w-full overflow-auto rounded-md section">
          <template
            v-if="
              notificationPageStore.unreadNotifications.length ||
              notificationPageStore.readNotifications.length
            "
          >
            <template v-if="notificationPageStore.unreadNotifications.length">
              <h4 class="ui-heading">Unread</h4>
              <div class="ui-list">
                <template
                  v-for="(
                    notification, i
                  ) in notificationPageStore.unreadNotifications"
                  :key="i"
                >
                  <div class="overflow-hidden rounded-md">
                    <NotificationMention
                      v-if="
                        notification.type ===
                          'App\\Notifications\\UserMentionedInPost' &&
                        userStore.usersById[notification.data.user_id] &&
                        postStore.postsById[notification.data.post_id]
                      "
                      :user="userStore.usersById[notification.data.user_id]"
                      :post-text="
                        postStore.postsById[notification.data.post_id].text
                      "
                      :post-id="notification.data.post_id"
                      :compact="false"
                      :readable-date="
                        postStore.postsById[notification.data.post_id]
                          .created_at_readable
                      "
                    />
                  </div>
                </template>
              </div>
            </template>

            <template v-if="notificationPageStore.readNotifications.length">
              <h4 class="ui-heading">Recent</h4>
              <div class="ui-list">
                <template
                  v-for="(
                    notification, i
                  ) in notificationPageStore.readNotifications"
                  :key="i"
                >
                  <div class="overflow-hidden rounded-md">
                    <NotificationMention
                      v-if="
                        notification.type ===
                          'App\\Notifications\\UserMentionedInPost' &&
                        postStore.postsById[notification.data.post_id] &&
                        userStore.usersById[notification.data.user_id]
                      "
                      :user="userStore.usersById[notification.data.user_id]"
                      :post-text="
                        postStore.postsById[notification.data.post_id].text
                      "
                      :post-id="notification.data.post_id"
                      :compact="false"
                      :readable-date="
                        postStore.postsById[notification.data.post_id]
                          .created_at_readable
                      "
                    />
                  </div>
                </template>
              </div>
            </template>
          </template>
          <template
            v-if="
              notificationPageStore.readNotifications.length === 0 &&
              notificationPageStore.unreadNotifications.length === 0 &&
              notificationPageStore.loading &&
              !notificationPageStore.errorWhileLoading
            "
          >
            <div class="ui-list">
              <LoadingNotificationListItem
                v-for="i in Array(20)"
                :key="i"
              /></div
          ></template>
          <template
            v-else-if="
              notificationPageStore.readNotifications.length === 0 &&
              notificationPageStore.unreadNotifications.length === 0 &&
              !notificationPageStore.loading &&
              !notificationPageStore.errorWhileLoading
            "
          >
            <EmptyPlaceholder
              bordered
              icon="far fa-folder-open"
              title="You have 0 notifications"
              :text="[`It's time you made some friends!`]"
            />
          </template>
        </section>
      </div>

      <ContentSideBar>
        <Card :rounded="true" :padded="false" class="w-64 min-w-[16rem]">
          <ul
            class="py-4 space-y-4 text-dynamic-neutral-700 dark:text-dynamic-neutral-200"
          >
            <li class="px-4 space-x-2">
              <span class="font-bold text-theme-500">
                <i class="fad fa-history"></i>
              </span>
              <span>Timeline</span>
            </li>

            <li class="px-4 space-x-2">
              <span class="font-bold text-theme-500">
                <i class="fad fa-fire"></i>
              </span>
              <span>Popular</span>
            </li>
          </ul>
        </Card>
      </ContentSideBar>
    </CenteredContainer>
  </PageContainer>
</template>
@/stores/resources/posts-store@/stores/resources/users-store
@/stores/activities/notification-page-store
