<script setup lang="ts">
import { ProfilePhoto } from "@allaxis/ui";

import LinkCardPreview from "@/components/LinkCardPreview.vue";
import UserTextTransform from "@/components/UserTextTransform.vue";

import useAsset from "@/hooks/useAsset";

import MessagePhotoStack from "./MessagePhotoStack.vue";

type PropTypes = {
  message: Message;
  isCurrentUser: boolean;
  showIcon: boolean;
};

defineProps<PropTypes>();

const asset = useAsset();
</script>

<template>
  <li
    class="w-full"
    :class="{
      'flex flex-row-reverse': !isCurrentUser,
      'flex flex-row': isCurrentUser,
      'px-10': showIcon,
      'px-2': !showIcon,
    }"
    v-if="message.links.length || message.photos.length"
  >
    <LinkCardPreview
      class="w-full max-w-sm"
      v-if="message.links.length"
      :link="message.links[0]"
    />
    <MessagePhotoStack
      :class="{
        'flex flex-row-reverse': !isCurrentUser,
        'flex flex-row': isCurrentUser,
      }"
      :photos="message.photos"
      v-if="message.photos.length"
    />
  </li>
  <li
    :class="{
      'flex flex-row-reverse': !isCurrentUser,
      'flex flex-row': isCurrentUser,
    }"
    class="relative items-end"
  >
    <ProfilePhoto
      v-if="showIcon"
      size="sm"
      :placeholder-text="(message.from_user as User).acronym"
      :image-url="
        asset('profile/small/' + (message.from_user as User).profile_photo?.filename, !!(message.from_user as User).profile_photo)
      "
    />
    <div
      :class="{
        'bg-dynamic-neutral-200 bg-tint-opacity-100 dark:bg-dynamic-neutral-800 dark:text-white text-dynamic-neutral-800 dark:text-dynamic-neutral-50':
          !!isCurrentUser,
        ' bg-dynamic-neutral-200 theme-tint dark:bg-dynamic-neutral-800 tint-rate-20 text-right text-theme-aware-neutral-200 right-message-bubble dark:text-theme-aware-neutral-800 bg-tint-opacity-100':
          !isCurrentUser,
      }"
      class="relative flex flex-col px-4 py-2 mx-2 leading-8 rounded-lg message-bubble"
    >
      <h4 class="font-semibold leading-none text-md">
        {{ message.from_user?.name }}
      </h4>
      <p class="z-[2]">
        <UserTextTransform :text="message.text" />
      </p>
      <p
        class="text-xs text-dynamic-neutral-700 dark:text-dynamic-neutral-300 z-[2]"
      >
        {{ (message as any).created_at_readable }}
      </p>
    </div>
    <span v-if="showIcon" class="flex-shrink-0 w-8 md:flex"></span>
  </li>
</template>

<style lang="postcss">
.message-bubble::after {
  content: "";
  position: absolute;
  bottom: 15px;
  left: -25px;
  width: 70px;
  height: 30px;
  background-color: inherit;
  transform: rotate(325deg) skew(55deg);
  transform-origin: top right;
  border-radius: 15% 0 0 0 / 25%;
  z-index: 0;
}

.message-bubble.right-message-bubble::after {
  @apply bg-tint-opacity-100;
  left: unset;
  right: 30px;
  transform: rotate(360deg) skew(55deg);
  border-radius: 0 0 15% 0 / 25%;
  bottom: 5px;
}
</style>
