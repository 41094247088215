<script lang="ts" setup>
import { Card, ProfilePhoto, Input, Button } from "@allaxis/ui";
import { onMounted, ref, computed } from "vue";
import { useRoute, useRouter } from "vue-router";

import EmptyPlaceholder from "@/components/EmptyPlaceholder.vue";
import LoadingChatListItem from "@/components/LoadingChatListItem.vue";
import MobileLandscapeNav from "@/components/MobileLandscapeNav.vue";
import MobileNav from "@/components/MobileNav.vue";
import NavBar from "@/components/NavBar.vue";
import PageContainer from "@/components/PageContainer.vue";
import TitleBar from "@/components/TitleBar.vue";

import useMessagesPageStore from "@/stores/activities/messages-page-store";
import useAuthStore from "@/stores/common/auth";
import useUiStore from "@/stores/common/ui";
import useChatStore from "@/stores/resources/new-chat-store";
import useUsersStore from "@/stores/resources/users-store";

import useAsset from "@/hooks/useAsset";
import useMessageActions from "@/hooks/useMessageActions";
import useVaporUpload from "@/hooks/useVaporUpload";

import { ROUTES } from "@/router";

const messagesPageStore = useMessagesPageStore();
const chatStore = useChatStore();
const route = useRoute();
const router = useRouter();
const uiStore = useUiStore();
const authStore = useAuthStore();
const userStore = useUsersStore();

const chatSearchInput = ref("");

const chatWindow = ref(null);

const { handleMessageCreation } = useMessageActions();

const messageInputText = ref("");

const asset = useAsset();

const user = computed(() =>
  route.name === ROUTES.MESSAGE_THREAD
    ? userStore.usersById[
        userStore.userIdByUsernameMap[
          (route.params.username as string).toLowerCase()
        ]
      ]
    : {}
);

const filteredChats = computed(() => {
  return messagesPageStore.chats.filter((chat) =>
    chat?.header.toLowerCase().includes(chatSearchInput.value.toLowerCase())
  );
});

const tabs = [
  { icon: "fa-home", route: { name: ROUTES.HOME } },
  { icon: "fa-search", route: { name: ROUTES.EXPLORE } },
  { icon: "fa-at", route: { name: ROUTES.NOTIFICATIONS } },
  { icon: "fa-envelope", route: { name: ROUTES.MESSAGES } },
];

const isSending = ref(false);
const photos = ref<Array<File>>([]);
const photoUrls = ref<Array<string>>([]);

const { upload } = useVaporUpload();

function handleInput(event: Event) {
  const element = event.target as HTMLInputElement;
  const files = element.files as FileList;

  const localPhotoUrls: Array<string> = [];
  const localPhotos: Array<File> = [];

  Array.from(files).forEach((file) => {
    localPhotoUrls.push(URL.createObjectURL(file));
    localPhotos.push(file);
  });

  photoUrls.value.push(...localPhotoUrls);
  photos.value.push(...localPhotos);
}

async function handleMessageInput() {
  isSending.value = true;
  const localText = messageInputText.value;
  const s3PhotoObjects = await Promise.all(
    photos.value.map(async (photo) => {
      const response = await upload(photo);
      return {
        uuid: response.uuid,
        key: response.key,
        content_type: photo.name,
      };
    })
  );

  handleMessageCreation({
    text: localText,
    photos: s3PhotoObjects,
    onSuccess: () => {
      isSending.value = false;
      messageInputText.value = "";
      photoUrls.value = [];
      photos.value = [];
    },
  });
}

onMounted(async () => {
  // await messagesPageStore.loadChats();
});
</script>

<template>
  <PageContainer title="Messages">
    <template #header="{ toggleSidebar }">
      <NavBar
        @left-button-tap="toggleSidebar"
        show-divider
        @right-button-tap="() => $router.push({ name: ROUTES.SETTINGS })"
        :right-icon="'far fa-sliders text-md'"
      />
      <template v-if="route.name === ROUTES.MESSAGES">
        <TitleBar
          title="Messages"
          :show-divider="true"
          left-icon="far fa-demo"
          @left-button-tap="toggleSidebar"
          right-icon="far fa-plus"
          @right-button-tap="uiStore.openContactModal"
        >
          <!-- <template #leading-slot><span></span></template> -->
          <!-- <template #trailing-slot><span></span></template> -->
        </TitleBar>
      </template>
      <template v-else-if="route.name === ROUTES.MESSAGE_THREAD">
        <TitleBar
          :title="(user as User)?.name ?? ''"
          :sub-title="(user as User)?.username ?? ''"
          :show-divider="true"
          :has-mobile-back-button="true"
        >
          <template #default>
            <div
              class="flex flex-row items-center justify-center flex-grow flex-shrink w-full max-w-screen-lg px-4 space-x-2 text-center"
            >
              <ProfilePhoto
                :placeholder-text="(user as User).acronym"
                :image-url="asset('profile/small/' + (user as User).profile_photo?.filename, !!(user as User).profile_photo)"
                size="sm"
              />
              <div class="flex flex-col">
                <h4 class="text-md">
                  {{ (user as User)?.name ?? "" }}
                </h4>
                <p class="text-xs text-neutral-700/70" v-if="user">
                  @{{ (user as User)?.username ?? "" }}
                </p>
              </div>
            </div>
          </template>
          <template #leading-slot>
            <span class="w-16 xs:w-20">
              <button
                aria-label="Back"
                @click="router.push({ name: ROUTES.MESSAGES })"
                class="flex items-center justify-center w-10 h-10 rounded-full"
              >
                <i class="text-xl far fa-arrow-left"></i>
              </button>
            </span>
          </template>
        </TitleBar>
      </template>
    </template>

    <div
      :class="{
        'md:h-[calc(100vh-4rem)]': route.name === ROUTES.MESSAGE_THREAD,
      }"
      class="relative flex flex-col items-center justify-start w-full lg:!h-[calc(100vh-4rem)] min-h-[calc(100vh-8.5rem)] md:h-[calc(100vh-4rem)] aspect-landscape:md:!min-h-full aspect-landscape:md:h-screen md:p-4 lg:p-10 text-dynamic-neutral-700 dark:text-dynamic-neutral-200"
    >
      <Card
        class="relative w-full h-full max-w-screen-xl border-none lg:border-solid md:rounded-md"
        :padded="false"
        :rounded="false"
      >
        <div class="flex flex-row h-full">
          <div
            :class="{
              flex: route.name === ROUTES.MESSAGES,
              hidden: route.name === ROUTES.MESSAGE_THREAD,
            }"
            id="chat-list-container"
            class="flex-col flex-shrink-0 w-full h-full max-h-full border-r md:flex md:w-80 lg:w-96 border-dynamic-neutral-200 dark:border-dynamic-neutral-800"
          >
            <div
              id="chat-list-header"
              class="flex-row flex-shrink-0 hidden h-16 px-2 border-b md:flex border-dynamic-neutral-200 dark:border-dynamic-neutral-800"
            >
              <div class="flex flex-row items-center flex-grow space-x-2">
                <Input
                  v-model="chatSearchInput"
                  id="chat-list-search"
                  class="flex-grow h-10"
                  placeholder="Search..."
                  leading-icon="far fa-search"
                />
                <Button
                  class="h-10 border-none"
                  rounded
                  @click="uiStore.openContactModal"
                  icon="far fa-plus"
                />
              </div>
            </div>

            <ul
              id="chat-list"
              class="flex-grow h-full p-2 space-y-2 overflow-scroll"
            >
              <template v-if="filteredChats.length">
                <template v-for="(chat, index) in filteredChats" :key="index">
                  <RouterLink
                    v-if="chat.dm_user_ids"
                    :to="{
                      name: ROUTES.MESSAGE_THREAD,
                      params: {
                        username: chatStore.chatIdUsernameMap.get(chat.id),
                      },
                    }"
                    custom
                    v-slot="{ navigate, isActive }"
                  >
                    <li
                      class="flex flex-row items-center px-4 rounded-md cursor-pointer"
                      :class="{
                        'bg-dynamic-neutral-100 dark:bg-dynamic-neutral-800':
                          isActive,
                      }"
                      @click="navigate"
                    >
                      <ProfilePhoto
                        :image-url="chat.imageUrl"
                        :placeholder-text="chat.iconText"
                      />

                      <div
                        class="flex flex-col justify-center flex-grow h-full py-4 pl-3 w-[80%]"
                      >
                        <div class="flex justify-between">
                          <span>{{ chat.header }}</span>
                          <span class="text-xs text-dynamic-neutral-500">{{
                            chat.lastMessageAt
                          }}</span>
                        </div>
                        <div
                          class="text-sm truncate text-dynamic-neutral-600 dark:text-dynamic-neutral-300"
                        >
                          {{ chat.preview }}
                        </div>
                      </div>
                    </li>
                  </RouterLink>

                  <RouterLink
                    v-else
                    :to="{
                      name: ROUTES.MESSAGE_GROUP_THREAD,
                      params: {
                        chatId: chat.id,
                      },
                    }"
                    custom
                    v-slot="{ navigate, isActive }"
                  >
                    <li
                      class="flex flex-row items-center px-4 rounded-md cursor-pointer"
                      :class="{
                        'bg-dynamic-neutral-100 dark:bg-dynamic-neutral-800':
                          isActive,
                      }"
                      @click="navigate"
                    >
                      <ProfilePhoto
                        :image-url="chat.imageUrl"
                        :placeholder-text="chat.iconText"
                      />

                      <div
                        class="flex flex-col justify-center flex-grow h-full py-4 pl-3 w-[80%]"
                      >
                        <div class="flex justify-between">
                          <span class="">{{ chat.header }}</span>
                          <span class="text-xs text-dynamic-neutral-500">{{
                            chat.lastMessageAt
                          }}</span>
                        </div>
                        <div
                          class="text-sm truncate text-dynamic-neutral-600 dark:text-dynamic-neutral-300"
                        >
                          {{ chat.preview }}
                        </div>
                      </div>
                    </li>
                  </RouterLink>
                </template>
              </template>

              <template
                v-else-if="
                  filteredChats.length === 0 &&
                  messagesPageStore.loading &&
                  !messagesPageStore.errorWhileLoading
                "
              >
                <LoadingChatListItem v-for="(_, i) in Array(10)" :key="i" />
              </template>

              <template v-else-if="messagesPageStore.errorWhileLoading">
                <EmptyPlaceholder
                  title="Error"
                  text="Error while loading message threads"
                  icon="far fa-circle-info"
                />
              </template>

              <template v-else>
                <div
                  class="flex flex-col items-center justify-center h-full p-8"
                >
                  <EmptyPlaceholder
                    class="m-0 md:-mt-24"
                    icon="far fa-messages"
                    title="No messages"
                    text="Send messages to your friends and family right from your
                phone."
                  />
                </div>
              </template>
            </ul>
          </div>

          <template v-if="route.name === 'messages'">
            <div
              id="chat-profile-container"
              class="flex-col items-center justify-center flex-grow hidden h-full bg-dynamic-neutral-200 bg-tint-opacity-20 dark:bg-dynamic-neutral-950 dark:bg-tint-opacity-50 md:flex"
            >
              <EmptyPlaceholder
                icon="far fa-message"
                title="No message thread selected"
                text="Send messages to your friends and family right from your phone."
              />
            </div>
          </template>

          <template
            v-if="
              route.name === ROUTES.MESSAGE_THREAD ||
              route.name == ROUTES.MESSAGE_GROUP_THREAD
            "
          >
            <RouterView
              class="bg-dynamic-neutral-200 bg-tint-opacity-20 dark:bg-dynamic-neutral-950 dark:bg-tint-opacity-50"
              ref="chatWindow"
            />
          </template>
        </div>
      </Card>
    </div>

    <template #footer>
      <template v-if="route.name === ROUTES.MESSAGES">
        <MobileNav />
      </template>
      <template
        v-else-if="
          route.name === ROUTES.MESSAGE_THREAD ||
          route.name == ROUTES.MESSAGE_GROUP_THREAD
        "
      >
        <div
          id="chat-message-input-container"
          class="sticky bottom-0 z-40 flex flex-col w-full p-2 space-x-2 space-y-2 border-t bg-dynamic-neutral-light pb-2-safe dark:bg-dynamic-neutral-900 border-dynamic-neutral-200 dark:border-dynamic-neutral-800 md:hidden"
        >
          <div
            class="flex flex-row w-full space-x-2 scroll-p-2"
            v-if="photoUrls.length"
          >
            <div
              class="h-16 overflow-hidden border-2 rounded-md border-neutral-200 dark:border-neutral-700"
              v-for="(photoUrl, index) in photoUrls"
              :key="index"
            >
              <img class="h-full max-w-full" :src="photoUrl" />
            </div>
          </div>
          <div class="flex flex-row items-center w-full space-x-2">
            <Button
              themed
              rounded
              type="primary"
              size="sm"
              icon="far fa-image"
              as="label"
              for="mobile-message-photo-input"
              class="cursor-pointer"
            />
            <input
              class="hidden"
              id="mobile-message-photo-input"
              type="file"
              multiple
              accept="image/gif, image/jpeg, image/png"
              @change="handleInput"
            />
            <Input
              id="message-input"
              placeholder="Message..."
              class="flex-grow rounded-3xl"
              v-model="messageInputText"
              @keyup.enter.stop="handleMessageInput"
            />
            <Button
              id="message-input"
              @click="handleMessageInput"
              type="primary"
              size="sm"
              themed
              rounded
              icon="far fa-paper-plane-top"
            />
          </div>
        </div>

        <MobileLandscapeNav :tabs="tabs" />
      </template>
    </template>
  </PageContainer>
</template>
