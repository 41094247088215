import useAppConfig from "@/hooks/useAppConfig";

export default function useAsset() {
  return function (url: string, condition?: boolean) {
    const appConfig = useAppConfig();

    if (
      (typeof condition !== "undefined" && !condition) ||
      !appConfig.value ||
      !appConfig.value.content_url
    )
      return "";

    return appConfig.value.content_url + url;
  };
}
