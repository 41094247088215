import { useOneSignal } from "@onesignal/onesignal-vue3";
import * as PusherPushNotifications from "@pusher/push-notifications-web";
import { deleteToken, onMessage } from "firebase/messaging";
import Echo from "laravel-echo";
import { defineStore } from "pinia";
import Pusher from "pusher-js";
import { ref } from "vue";

import useAuthStore from "@/stores/common/auth";

import useFirebaseMessaging from "@/hooks/useFirebaseMessaging";
import usePlatform from "@/hooks/usePlatformFeatureCheck";
import useUiNotifications from "@/hooks/useUiNotifications";

import usePostStore from "./posts-store";
import useUsersStore from "./users-store";

import api from "@/services/api";
import type { InitialRequestData } from "@/services/app-service";
import {
  clearNotifications,
  getUnreadNotifications,
} from "@/services/notification-service";
import type { IPageData } from "@/services/page-service";
import getFirebaseToken from "@/utils/getFirebaseToken";

let beamsClient: PusherPushNotifications.Client;

const useNotificationStore = defineStore("notification", () => {
  const totalUnreadCount = ref(0);
  const notificationsById = ref<Map<string, UserNotification>>(new Map());
  const notificationIds = ref<Set<string>>(new Set());

  const platform = usePlatform();

  const authStore = useAuthStore();
  const userStore = useUsersStore();
  const postStore = usePostStore();

  const oneSignal = useOneSignal();

  const { notify } = useUiNotifications();

  function init(data: InitialRequestData) {
    parseNotificatiosFromRequestData({
      unreadNotificationCount: data.activities.notifications.unreadNotification,
      notifications: data.resources.notifications,
    });
  }

  async function fetchUnreadNotifications() {
    try {
      await getUnreadNotifications();
    } catch (e) {
      console.log(
        "Something went wrong while fetching unread notifications. ",
        e
      );
    }
  }

  function addNotifications(incomingNotifications: Array<UserNotification>) {
    const notificationsByIdMap = new Map(notificationsById.value);
    const localNotificatonIds = new Set(Array.from(notificationIds.value));

    incomingNotifications.forEach((n) => {
      notificationsByIdMap.set(n.id, n);
      localNotificatonIds.add(n.id);
    });

    notificationsById.value = notificationsByIdMap;
    notificationIds.value = localNotificatonIds;
  }
  async function clearCurrentUnreadNotifications() {
    await clearNotifications(Array.from(notificationIds.value));
  }

  async function clearAllUnreadNotifications() {
    const { data } = await clearNotifications();
    setUnreadCount(0);
    parseNotificatiosFromRequestData(data);
  }

  function setUnreadCount(count: number) {
    totalUnreadCount.value = count;
  }

  function parseNotificatiosFromRequestData(data: IPageData) {
    const users: Array<User> = [];
    const uniqueUserIds = new Set<number>();
    const posts: Array<Post> = [];
    const uniquePostIds = new Set<number>();

    const parsedNotifications = (data.notifications || []).map(
      (notification) => {
        if (notification.post && uniquePostIds.has(notification.post.id)) {
          posts.push(notification.post);
          delete notification.post;
        }

        if (
          notification.notifier &&
          uniqueUserIds.has(notification.notifier.id)
        ) {
          users.push(notification.notifier);
          delete notification.notifier;
        }

        return {
          ...notification,
        };
      }
    );

    userStore.parseUsers(users);
    postStore.parsePosts(posts);

    if (data.notifications) addNotifications(parsedNotifications);

    if (data.unreadNotificationCount)
      setUnreadCount(data.unreadNotificationCount);

    // setUnreadCount(unreadCount);
  }

  async function setupOneSignalPushNotifications() {
    if (!authStore.user) return;
    if (!oneSignal.Notifications.isPushSupported) return;
    // if (
    //   !(
    //     window.location.protocol == "https" &&
    //     import.meta.env.VITE_PUSHER_BEAMS_INSTANCE &&
    //     import.meta.env.PROD
    //   ) ||
    //   import.meta.env.DEV
    // )
    //   return;

    const userId = authStore.user.id.toString();

    oneSignal.init({
      appId: import.meta.env.VITE_ONE_SIGNAL_APP_ID,
      serviceWorkerParam: { scope: "/" },
      serviceWorkerPath: "/service-worker.js",
    });

    oneSignal.login(userId).then(() =>
      notify({
        title: "Boot up Push notifications with user id " + userId,
        type: "info",
      })
    );
  }

  async function stopOneSignalPushNotifications() {
    await oneSignal.logout();
  }

  async function setupFirebasePushNotifications() {
    if (!authStore.isAuthenticated) return;
    const swRegistrationReady =
      import.meta.env.PROD && (await window.navigator.serviceWorker.ready);
    if (
      import.meta.env.PROD &&
      swRegistrationReady &&
      platform.hasSupport("notification") &&
      "Notification" in window &&
      Notification.permission === "granted"
    ) {
      const duration = 10000;
      try {
        const messagingInstance = useFirebaseMessaging();

        const firebaseToken = await getFirebaseToken(messagingInstance);

        const existingToken = localStorage.getItem("firebase-token");
        let tokenToBeReplaced = undefined;

        if (
          typeof existingToken === "string" &&
          existingToken !== firebaseToken
        ) {
          tokenToBeReplaced = existingToken;
        }

        await api.post("save-firebase-messaging-token", {
          token: firebaseToken,
          tokenToBeReplaced,
        });

        localStorage.setItem("firebase-token", firebaseToken);

        onMessage(messagingInstance, (payload) =>
          showInAppNotification(payload)
        );
      } catch (e) {
        notify({
          title: "Firebase setup error.",
          text: e as string,
          duration,
        });
        console.log(
          "Something went wrong while setting up Firebase Push notifications via FCM",
          e
        );
      }
    }
  }

  async function setupPusherBeamsPushNotifications() {
    if (!authStore.user) return;
    if (
      (window.location.protocol == "https" &&
        import.meta.env.VITE_PUSHER_BEAMS_INSTANCE &&
        import.meta.env.PROD) ||
      (import.meta.env.DEV && import.meta.env.VITE_PUSHER_BEAMS_INSTANCE)
    )
      return;

    const swRegistration = await window.navigator.serviceWorker.ready;

    beamsClient = new PusherPushNotifications.Client({
      instanceId: import.meta.env.VITE_PUSHER_BEAMS_INSTANCE,
      serviceWorkerRegistration: swRegistration,
    });

    const userId = authStore.user.id.toString();

    beamsClient
      .start()
      .then(() => beamsClient.addDeviceInterest("debug-allaxis"))
      .then(() => {
        const beamsTokenProvider = new PusherPushNotifications.TokenProvider({
          url:
            import.meta.env.VITE_BASE_URL +
            import.meta.env.VITE_API_PREFIX +
            "/beams-auth",
          credentials: "include",
        });

        beamsClient.setUserId(userId, beamsTokenProvider);
      })
      .then(() => console.log("Successfully registered and subscribed!"))
      .then(() => {
        notify({
          title: "Boot up Push notifications with user id " + userId,
          type: "info",
        });
      });
  }

  function stopPusherBeamsPushNotifications() {
    if (beamsClient) beamsClient.stop();
    notify({
      title: "Unsubscribed from Push notifications",
    });
  }

  async function stopFirebasePushNotifications() {
    if (!authStore.isAuthenticated) return;
    if (
      import.meta.env.PROD &&
      platform.hasSupport("notification") &&
      "Notification" in window &&
      Notification.permission === "granted"
    ) {
      const messagingInstance = useFirebaseMessaging();
      const localFirebaseToken = localStorage.getItem("firebase-token");
      if (!localFirebaseToken) return;
      try {
        await api.delete(
          `/delete-firebase-messaging-token/${localFirebaseToken}`
        );

        deleteToken(messagingInstance);
      } catch (e) {
        notify({
          title: "Error removing token",
          text: `Token: ${localFirebaseToken}`,
          duration: 5000,
        });
      }
    }
  }

  function setupPusherWebSockets() {
    (window as any).Pusher = Pusher;
    if (authStore.user && import.meta.env.VITE_PUSHER_APP_KEY) {
      (window as any).Echo = new Echo({
        broadcaster: "pusher",
        key: import.meta.env.VITE_PUSHER_APP_KEY,
        cluster: import.meta.env.VITE_PUSHER_APP_CLUSTER,
        forceTLS: true,
        encrypted: true,
        authorizer: (channel: { name: string }) => {
          return {
            authorize: (
              socketId: string,
              callback: (r: boolean, data: object) => void
            ) => {
              api
                .post(
                  "/broadcasting/auth",
                  {
                    socket_id: socketId,
                    channel_name: channel.name,
                  },
                  {
                    headers: { "X-Socket-Id": socketId },
                    baseURL: import.meta.env.VITE_BASE_URL,
                    withCredentials: true,
                  }
                )
                .then((response) => {
                  callback(false, response.data);
                })
                .catch((error) => {
                  callback(true, error);
                });
            },
          };
        },
      });
      (window as any).Echo.private(
        `App.Models.User.${authStore.user.id}`
      ).notification((notification: any) => {
        fetchUnreadNotifications();
        console.log("NEW NOTIFICATION: ", notification);
        // TODO: Maybe fetch unread notifications.
      });
    }
  }

  function startPushNotifications() {
    // setupPusherBeamsPushNotifications();
    // setupOneSignalPushNotifications();
    setupFirebasePushNotifications();
  }

  function stopPushNotifications() {
    // stopPusherBeamsPushNotifications();
    // stopOneSignalPushNotifications();
    stopFirebasePushNotifications();
  }

  function startWebSockets() {
    setupPusherWebSockets();
  }

  function stopWebSockets() {
    // TODO: Any way to unsubcribe from push notifications?
  }

  function showInAppNotification(data: any) {
    totalUnreadCount.value = totalUnreadCount.value + 1;
    notify({
      title: data.notification.title,
      text: data.notification.body,
      icon: "far fa-envelope",
      type: "info",
    });
    console.log({
      inAppNotificationData: data,
    });
  }

  return {
    notificationsById,
    notificationIds,

    addNotifications,
    clearCurrentUnreadNotifications,
    clearAllUnreadNotifications,
    setUnreadCount,
    parseNotificatiosFromRequestData,
    totalUnreadCount,

    startWebSockets,
    stopWebSockets,
    startPushNotifications,
    stopPushNotifications,

    init,
  };
});

export default useNotificationStore;
