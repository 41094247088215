import { acceptHMRUpdate, defineStore } from "pinia";
import { ref } from "vue";

import useReactionStore from "@/stores/resources/reactions-store";
import useUsersStore from "@/stores/resources/users-store";

import useBookmarkStore from "./bookmarks-store";
import useNetworkStore from "./networks-store";

import type { InitialRequestData } from "@/services/app-service";
import {
  getPostTimeline,
  publishPost,
  deletePost,
  publishThread,
} from "@/services/posts-service";
import { fetchPostsByTag } from "@/services/tag-service";
import callOptional from "@/utils/callOptional";

export type postCreationOptions = {
  networkSlug?: string;
  text: string;
  photos?: Array<Record<string, string>>;
  parentPostId?: number;
  onSuccess?: (...args: (Post | unknown)[]) => void;
  onError?: () => void;
};

export type postThreadCreationOptions = {
  networkSlug?: string;
  posts: {
    text: string;
    photos?: Array<Record<string, string>>;
  }[];
  parentPostId?: number;
  onSuccess?: (...args: (Post | unknown)[]) => void;
  onError?: () => void;
};

export type postRemovalOptions = {
  postId: number;
  onSuccess?: () => void;
  onError?: () => void;
};

const usePostStore = defineStore("posts", () => {
  const postsById = ref<Record<number, Post>>({});
  const postIdsByUsername = ref<Record<string, Set<number>>>({});
  const postIdsByTag = ref<Record<string, Set<number>>>({});
  const postIdsByNetworkSlug = ref<Map<string, Set<number>>>(new Map());

  const linkIdsByPostId = ref<Map<number, Set<number>>>(new Map());
  const linksById = ref<Map<number, Link>>(new Map());

  const usersStore = useUsersStore();
  const networkStore = useNetworkStore();
  const reactionStore = useReactionStore();
  const bookmarkStore = useBookmarkStore();

  function init(data: InitialRequestData) {
    parsePosts(data.resources.posts);
  }

  /**
   * Store posts by id in JSON object.
   * @param posts Posts to set.
   */
  function setPostsById(posts: Array<Post>) {
    posts.forEach((post) => {
      if (post === undefined || post === null) return;
      const user = usersStore.usersById[post.user_id];
      if (!user) return;
      if (post.reply_to) {
        postsById.value[post.reply_to_id as unknown as number] = post.reply_to;
      }
      delete post.reply_to as unknown as string;
      postsById.value[post.id] = post;
    });
  }

  /**
   * Store post ids by username in set.
   * @param posts Posts to set.
   */
  function setPostIdsByUsername(posts: Array<Post>) {
    posts.forEach((post) => {
      const user = usersStore.usersById[post.user_id];
      if (!user) return;
      const username = user.username.toLowerCase();
      if (!postIdsByUsername.value[username])
        postIdsByUsername.value[username] = new Set();
      postIdsByUsername.value[username].add(post.id);
    });
  }

  /**
   * Store post ids by Network slug.
   * @param posts Posts to set.
   */
  function setPostIdsByNetworkSlug(posts: Array<Post>) {
    const localPostNetworkMap = new Map([...postIdsByNetworkSlug.value]);
    posts.forEach((post) => {
      const network = networkStore.networksBySlug.get(
        post?.network?.slug as string
      );

      if (!network) return;
      const slug = network.slug;

      if (!localPostNetworkMap.has(slug)) {
        localPostNetworkMap.set(slug, new Set());
      }
      localPostNetworkMap.get(slug)?.add(post.id);
    });

    postIdsByNetworkSlug.value = new Map([
      ...postIdsByNetworkSlug.value,
      ...localPostNetworkMap,
    ]);
  }

  function setLinkIdsByPostId(posts: Array<Post>) {
    const linkIdsByPostIdMap = linkIdsByPostId.value;
    const linksByIdMap = linksById.value;
    posts.forEach(function (post) {
      if (post.links) {
        post.links.forEach((link) => {
          linksByIdMap.set(link.id, link);
          if (!linkIdsByPostIdMap.has(post.id))
            linkIdsByPostIdMap.set(post.id, new Set());
          (linkIdsByPostIdMap.get(post.id) as Set<number>).add(link.id);
        });
      }
    });

    linksById.value = linksByIdMap;
    linkIdsByPostId.value = linkIdsByPostIdMap;
  }

  /**
   * Parse posts and their relationships.
   * @param posts Posts to parse.
   * @returns Promise that resolves the parsed posts.
   */
  function parsePosts(posts: Array<Post>): Array<Post> {
    const uniqueNetworkSlugs = new Set<string>();
    const uniqueUserIds = new Set<number>(); // without this, we get a user per post, even if they're the same user.
    const networks: Array<Network> = [];
    const users: Array<User> = [];

    posts.forEach((post) => {
      if (!post) return;

      if (post.user && !uniqueUserIds.has(post.user.id)) {
        uniqueUserIds.add(post.user.id);
        users.push(post.user);
      }

      if (post.network && !uniqueNetworkSlugs.has(post.network.slug)) {
        uniqueNetworkSlugs.add(post.network.slug);
        networks.push(post.network);
      }
    });

    if (users.length !== 0) {
      usersStore.parseUsers(users as Array<User>);
    }

    if (networks.length !== 0) {
      networkStore.parseNetworks(networks);
    }

    setLinkIdsByPostId(posts);
    setPostIdsByNetworkSlug(posts);

    setPostsById(
      posts.map((post) => {
        if (post.user) delete post.user;
        return post;
      })
    );
    setPostIdsByUsername(posts);
    return posts;
  }

  async function getPosts() {
    const { data: posts } = await getPostTimeline();
    parsePosts(posts);
  }

  async function getPostsByTag(tag: string) {
    const normalizedTag = tag.toLowerCase();
    const { data } = await fetchPostsByTag(normalizedTag);
    const localPostIdsByTag = postIdsByTag.value;

    if (!localPostIdsByTag[normalizedTag])
      localPostIdsByTag[normalizedTag] = new Set();

    data.posts.forEach((p) => localPostIdsByTag[normalizedTag].add(p.id));

    postIdsByTag.value = localPostIdsByTag;

    reactionStore.parseReactions(data.reactions);
    bookmarkStore.parseBookmarks(data.bookmarks);
    parsePosts(data.posts);
  }

  function removeUserPost(username: string, postId: number) {
    if (!postIdsByUsername.value[username]) return;
    postIdsByUsername.value[username].delete(postId);
  }

  async function createPost(options: postCreationOptions) {
    try {
      const { data } = await publishPost(
        options.networkSlug ?? undefined,
        options.text,
        options.parentPostId,
        options.photos ?? undefined
      );
      if (options.parentPostId && data.reply) {
        parsePosts([data.post as Post, data.reply as Post]);
        callOptional(options.onSuccess, data.post, data.reply);
      } else {
        parsePosts([data.post]);
        callOptional(options.onSuccess, data.post);
      }
    } catch (e) {
      callOptional(options.onError);
    }
  }

  async function createPostThread(options: postThreadCreationOptions) {
    try {
      const { data } = await publishThread(
        options.networkSlug ?? undefined,
        options.posts,
        options.parentPostId
      );
      parsePosts([data.post, ...data.replies]);
      callOptional(options.onSuccess, data.post, ...data.replies);
    } catch (e) {
      callOptional(options.onError);
    }
  }

  async function removePost(options: postRemovalOptions) {
    try {
      const post = postsById.value[options.postId];
      if (!post) return;
      const user = usersStore.usersById[post.user_id];
      if (!user) return;
      const { data } = await deletePost(options.postId);
      usersStore.parseUsers([data.user]);
      callOptional(options.onSuccess, post);
      removeUserPost(data.user.username, options.postId);
    } catch (e) {
      callOptional(options.onError);
    }
  }

  return {
    postsById,
    postIdsByUsername,
    setPostsById,
    parsePosts,
    getPosts,
    createPost,
    createPostThread,
    removeUserPost,
    removePost,
    linksById,
    linkIdsByPostId,
    postIdsByNetworkSlug,
    getPostsByTag,
    postIdsByTag,
    init,
  };
});

export default usePostStore;

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(usePostStore, import.meta.hot));
}
