<script lang="ts" setup>
import { useRoute } from "vue-router";

import PostCompose from "@/components/PostCompose.vue";

import useHomePageStore from "@/stores/activities/home-page-store";
import useNetworkPageStore from "@/stores/activities/network-page-store";
import usePostDetailPageStore from "@/stores/activities/post-detail-page-store";
import useProfilePageStore from "@/stores/activities/profile-page-store";

import { ROUTES } from "@/router";
import type { PostPayloadType } from "@/types/appTypes";
import type { PostDetailRouteType } from "@/types/routeTypes";

const emit = defineEmits<EmitTypes>();

const homePageStore = useHomePageStore();
const postDetailPageStore = usePostDetailPageStore();
const profilePageStore = useProfilePageStore();
const networkPageStore = useNetworkPageStore();

const route = useRoute() as PostDetailRouteType;

async function handlePostCreation(
  { text, photos }: PostPayloadType,
  onComplete: () => void
) {
  try {
    switch (route.name) {
      case ROUTES.HOME:
        await homePageStore.createPost({ text, photos }, onComplete);
        break;
      case ROUTES.POST_DETAIL:
        await postDetailPageStore.createReply(
          { text, photos },
          route.params.postId
        );
        onComplete();
        break;
      case ROUTES.NETWORK_FEED:
        await networkPageStore.createPost({ text, photos }, onComplete);
        break;
      default:
        await profilePageStore.createPost({ text, photos }, onComplete);
        break;
    }
  } catch (e) {
    console.log("POST COMPOSE MODAL: Something went wrong posting", e);
  } finally {
    onComplete();
    emit("closeContextTap"); // Just being lazy here. Create another event...
  }
}

function handleClosetap(event: MouseEvent | TouchEvent) {
  event.stopPropagation();
  emit("closeContextTap");
}

type EmitTypes = {
  (e: "closeContextTap"): void;
};
</script>

<template>
  <div
    @click="handleClosetap"
    class="fixed top-0 left-0 z-50 flex flex-col items-center justify-start w-full h-full glass-blur bg-dynamic-dark/20 md:hidden"
  >
    <div
      class="relative flex flex-col items-center justify-center w-full px-2 top-2-safe"
    >
      <div
        @click.stop
        class="w-full max-w-screen-sm rounded-md !shadow-black/50 !shadow-2xl bg-dynamic-neutral-light ui-item dark:bg-dynamic-neutral-900"
      >
        <div
          class="flex flex-row justify-between p-2 border-b border-dynamic-neutral-200 dark:border-dynamic-neutral-700 dark:text-dynamic-neutral-200 text-dynamic-neutral-800"
        >
          <span class="flex-grow p-2"></span>
          <h4 class="p-2 font-semibold">Quick note</h4>
          <span class="flex items-center justify-end flex-grow p-2">
            <button @click="handleClosetap">
              <i class="far fa-xmark"></i>
            </button>
          </span>
        </div>
        <PostCompose class="w-full p-2 py-2" @publish="handlePostCreation" />
      </div>
    </div>
  </div>
</template>
