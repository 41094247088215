<script lang="ts" setup>
import { Input, Button } from "@allaxis/ui";
import { ref } from "vue";

import useAuthStore from "@/stores/common/auth";

const authStore = useAuthStore();

const user = ref("");
const password = ref("");
const isRememberMeChecked = ref(false);
const isLoggingIn = ref(false);

async function handleLoginFormSubmit(event: Event) {
  event.preventDefault();
  isLoggingIn.value = true;
  await new Promise((resolve) => setTimeout(() => resolve(0), 2000));
  await authStore.sessionStart(
    user.value,
    password.value,
    isRememberMeChecked.value
  );
  isLoggingIn.value = false;
}
</script>

<template>
  <div
    class="flex flex-col items-center w-full h-screen px-4 pt-10-safe bg-dynamic-neutral-100 dark:bg-dynamic-neutral-950"
  >
    <div class="flex items-center justify-center mb-10">
      <a class="w-20 h-20" href="/">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 360 360"
          version="1.1"
          xml:space="preserve"
          style="
            fill-rule: evenodd;
            clip-rule: evenodd;
            stroke-linejoin: round;
            stroke-miterlimit: 2;
          "
          class="w-full h-full fill-current text-dynamic-neutral-500 drop-shadow-lg"
        >
          <g id="layer1">
            <path
              id="path3346"
              d="M30.182,91.997l150,-86.603l-0.002,173.204l-149.998,-86.601Z"
              style="fill: #ffad00"
              shape-rendering="crispEdges"
            />
            <path
              id="path3348"
              d="M30.182,265.202l149.998,-86.604l-0.002,173.204l-149.996,-86.6Z"
              style="fill: #635bff"
              shape-rendering="crispEdges"
            />
            <path
              id="path3350"
              d="M180.18,178.598l149.998,-86.603l0.007,173.208l-150.005,-86.605Z"
              style="fill: #b5de01"
              shape-rendering="crispEdges"
            />
            <path
              id="path3352"
              d="M30.182,91.997l-0,173.204l149.998,-86.603l-149.998,-86.601Z"
              style="fill: #9b5bff"
              shape-rendering="crispEdges"
            />
            <path
              id="path3354"
              d="M180.179,5.398l-0.001,173.205l149.998,-86.604l-149.998,-86.596l0.001,-0.005Z"
              style="fill: #ffad00"
              shape-rendering="crispEdges"
            />
            <path
              id="path3356"
              d="M180.18,178.598l-0.002,173.204l150.007,-86.599l-150.005,-86.605Z"
              style="fill: #00c4df"
              shape-rendering="crispEdges"
            />
          </g>
        </svg>
      </a>
    </div>
    <div
      class="relative w-full max-w-sm overflow-hidden bg-dynamic-neutral-light rounded-md shadow-md dark:bg-dynamic-neutral-900"
    >
      <div class="w-full h-full">
        <div class="px-4 py-10 sm:px-10">
          <form method="POST" @submit="handleLoginFormSubmit">
            <div>
              <label
                for="email"
                class="block text-sm font-medium leading-5 text-dynamic-neutral-700 dark:text-dynamic-neutral-500"
                >Email address</label
              >
              <div class="mt-1 rounded-md shadow-sm">
                <Input
                  id="email"
                  name="email"
                  v-model="user"
                  placeholder="Email"
                  leading-icon="fa-user"
                  required
                />

                <!-- <input
                  id="email"
                  type="email"
                  name="email"
                  placeholder="Email"
                  required
                  v-model="user"
                  class="block w-full px-3 py-2 transition duration-150 ease-in-out bg-transparent border rounded-md appearance-none placeholder-neutral-400 border-dynamic-neutral-300 dark:border-dynamic-neutral-800 dark:text-dynamic-neutral-200 focus:outline-none focus:shadow-outline-blue focus:border-blue-300 sm:text-sm sm:leading-5"
                /> -->
              </div>
            </div>
            <div class="mt-6">
              <label
                for="password"
                class="block text-sm font-medium leading-5 text-dynamic-neutral-700 dark:text-dynamic-neutral-500"
                >Password</label
              >
              <div class="mt-1 rounded-md shadow-sm">
                <Input
                  id="password"
                  name="password"
                  v-model="password"
                  placeholder="Password"
                  leading-icon="fa-lock"
                  type="password"
                  required
                />
                <!-- <input
                  id="password"
                  type="password"
                  name="password"
                  placeholder="Password"
                  v-model="password"
                  required
                  class="block w-full px-3 py-2 transition duration-150 ease-in-out bg-transparent border rounded-md appearance-none placeholder-neutral-400 border-dynamic-neutral-300 dark:border-dynamic-neutral-800 dark:text-dynamic-neutral-200 focus:outline-none focus:shadow-outline-blue focus:border-blue-300 sm:text-sm sm:leading-5"
                /> -->
              </div>
            </div>
            <div class="flex items-center justify-between mt-6">
              <div class="flex items-center">
                <input
                  id="remember_me"
                  type="checkbox"
                  class="w-4 h-4 text-blue-600 transition duration-150 ease-in-out form-checkbox"
                  v-model="isRememberMeChecked"
                />
                <label
                  for="remember_me"
                  class="block ml-2 text-sm leading-5 text-dynamic-neutral-900 dark:text-dynamic-neutral-300"
                  >Remember me</label
                >
              </div>
            </div>
            <div class="mt-6 allaxis-indigo-theme">
              <Button
                text="Login"
                loading-text="Logging in..."
                :loading="isLoggingIn"
                themed
                centered
                is-submit
                type="primary"
                class="w-full text-center"
              />
              <!-- <button
                type="submit"
                class="flex items-center justify-center w-full px-4 py-2 text-base font-medium text-white bg-indigo-600 border border-transparent rounded-md shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
              >
                Login
              </button> -->
            </div>
          </form>
        </div>
        <div class="flex">
          <div class="flex flex-grow h-2 bg-allaxis-indigo"></div>
          <div class="flex flex-grow h-2 bg-allaxis-cyan"></div>
          <div class="flex flex-grow h-2 bg-allaxis-lime"></div>
          <div class="flex flex-grow h-2 bg-allaxis-yellow"></div>
        </div>
      </div>
    </div>
  </div>
</template>
@/stores/common/auth
