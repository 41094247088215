import AuthenticationRoute from "./authentication-route";
import Bookmark from "./bookmark-route";
import ExploreRoute from "./explore-route";
import HomeRoute from "./home-route";
import MessagesRoute from "./messages-route";
import NetworkPostComposeRoute from "./network-post-compose-route";
import NetwrokRoute from "./network-route";
import NotificationsRoute from "./notification-route";
import OnboardingRoute from "./onboarding-route";
import People from "./people-route";
import PostComposeRoute from "./post-compose-route";
import PostDetail from "./post-detail-route";
import PostReplyComposeRoute from "./post-reply-compose-route";
import ProfileRoute from "./profile-route";
import SettingRoute from "./settings-route";
import TagRoute from "./tag-route";
import TrendsRoute from "./trends-route";
// ROUTE IMPORTS

const DevelopmentSettings = () => import("@/apps/DeveloperSettings.vue");

export default [
  HomeRoute,
  AuthenticationRoute,
  ProfileRoute,
  MessagesRoute,
  ExploreRoute,
  SettingRoute,
  TrendsRoute,
  NetworkPostComposeRoute,
  NetwrokRoute,
  PostReplyComposeRoute,
  PostDetail,
  Bookmark,
  People,
  NotificationsRoute,
  TagRoute,
  OnboardingRoute,
  PostComposeRoute,
  // ROUTE EXPORTS

  {
    name: "dev",
    path: "/dev",
    component: DevelopmentSettings,
  },
];
