import { acceptHMRUpdate, defineStore } from "pinia";
import { computed, ref } from "vue";
import { useRoute } from "vue-router";

import useBookmarkStore from "@/stores/resources/bookmarks-store";
import useNetworkStore from "@/stores/resources/networks-store";
import useNotificationStore from "@/stores/resources/notifications-store";
import usePostStore from "@/stores/resources/posts-store";
import useReactionsStore from "@/stores/resources/reactions-store";
import useUserStore from "@/stores/resources/users-store";

import usePageState from "@/hooks/usePageState";
import usePostActions from "@/hooks/usePostActions";
import useUiNotifications from "@/hooks/useUiNotifications";

import { loadNetworkPageData } from "@/services/page-service";
import type { PostPayloadType } from "@/types/appTypes";
import type { NetworkRouteType } from "@/types/routeTypes";
import callOptional from "@/utils/callOptional";

const useNetworkPageStore = defineStore("network-page-store", function () {
  const { loading, fetchPageData, errorWhileLoading } = usePageState();
  const {
    createPost,
    removePost,
    toggleBookmark,
    toggleReaction,
    createPostThread,
  } = usePostActions();

  const networkSlug = ref<undefined | string>();

  const networkStore = useNetworkStore();
  const notificationStore = useNotificationStore();

  const { notify } = useUiNotifications();

  const postStore = usePostStore();
  const userStore = useUserStore();
  const bookmarkStore = useBookmarkStore();
  const reactionStore = useReactionsStore();

  const route = useRoute() as NetworkRouteType;

  const network = computed(() =>
    networkStore.networksBySlug.get(networkSlug.value as string)
  );

  const networkPosts = computed(() => {
    const slug = (route.params.slug as string).toLowerCase();
    return Array.from(postStore.postIdsByNetworkSlug.get(slug) || new Set())
      .map((id) => postStore.postsById[id as number])
      .map((post) => {
        const user = userStore.usersById[post.user_id];
        if (!user) return null;
        return {
          ...post,
          user,
          links: Array.from(postStore.linkIdsByPostId.get(post.id) || [])
            .map((id) => postStore.linksById.get(id))
            .filter((link) => link !== undefined) as Link[],
          isBookmarked: !!bookmarkStore.postBookmarksById.get(post.id),
          hasReacted: !!reactionStore.userRectionsByPostId.has(post.id),
        };
      })
      .filter((post) => post !== null);
  });

  function publishPostToNetworkFeed(
    { text }: PostPayloadType,
    onComplete?: (...args: unknown[]) => void
  ) {
    createPost({
      text,
      networkSlug: route.params.slug || undefined,
      onSuccess(post) {
        callOptional(onComplete, post);
        notify({
          title: "Post submitted successfully",
          type: "success",
        });
      },

      onError() {
        notify({
          title: "Post submission error",
          text: "Something went wrong while submitting your post.",
          type: "success",
        });
      },
    });
  }

  function publishPostThreadToNetworkFeed(
    {
      posts,
    }: {
      posts: Array<{ text: string; photos?: Array<Record<string, string>> }>;
    },
    onComplete?: (...args: unknown[]) => void
  ) {
    createPostThread({
      posts,
      networkSlug: route.params.slug || undefined,
      onSuccess(result) {
        callOptional(onComplete, result);
        notify({
          title: "Post submitted successfully",
          type: "success",
        });
      },
    });
  }

  function removePostFromProfileFeed(
    postId: number,
    onComplete?: (...args: unknown[]) => void
  ) {
    removePost({
      postId,
      onSuccess() {
        callOptional(onComplete);
      },

      onError() {
        notify({
          title: "Post removal error",
          text: "Something went wrong while removing post.",
          type: "error",
        });
      },
    });
  }

  async function loadPageData(slug: string) {
    await fetchPageData({
      async fetch() {
        await new Promise((r) => setTimeout(r, 1000));
        const response = await loadNetworkPageData(slug);
        const { data } = response;
        console.log({
          response,
          NETWORK_DATA: data,
          networkSlug: networkSlug.value,
        });

        networkSlug.value = data.network.slug;
        networkStore.parseNetworks([data.network]);

        bookmarkStore.parseBookmarks(data.bookmarks);
        reactionStore.parseReactions(data.reactions);
        postStore.parsePosts(data.posts);

        notificationStore.parseNotificatiosFromRequestData(data);
      },
    });
  }

  return {
    loadPageData,
    loading,
    network,

    posts: networkPosts,
    createPost: publishPostToNetworkFeed,
    createPostThread: publishPostThreadToNetworkFeed,
    removePost: removePostFromProfileFeed,
    toggleBookmark,
    toggleReaction,
    errorWhileLoading,
  };
});

export default useNetworkPageStore;

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useNetworkPageStore, import.meta.hot));
}
