import { useNotification } from "@kyvg/vue3-notification";

type UiNotificationType = {
  title: string;
  text?: string;
  duration?: number;
  icon?: string;
  type?: "warn" | "info" | "success" | "error" | "default";
  group?: string;
};

export default function useUiNotifications() {
  const notifications = useNotification();

  function notify({
    title = "",
    text = "",
    group = "app-notifications",
    type = "default",
    duration = 2000,
    icon,
  }: UiNotificationType) {
    console.log("App Notification");
    notifications.notify({
      title,
      text,
      group,
      duration,
      data: {
        type,
        icon,
      },
    });
  }

  return {
    notify,
  };
}
